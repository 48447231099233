import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { arrToMap } from "shared/utils";

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: '/api/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set('authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Files', 'Xls'],
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (orderId) => `files/?order=${orderId}`,
      //transformResponse: (response) => arrToMap(response),
      providesTags: ['Files']
    }),

    getLeadFiles: builder.query({
      query: (leadId) => `files/?lead=${leadId}`,
      transformResponse: (response) => arrToMap(response),
      providesTags: ['Files']
    }),

    getQuoteFiles: builder.query({
      query: (quoteId) => `files/?quote=${quoteId}`,
      transformResponse: (response) => arrToMap(response),
      providesTags: ['Files']
    }),

    uploadFile: builder.mutation({
      query: ({ files, orderId, fileType = "SCONOSCIUTO" }) => {
        const formData = new FormData();
        for (let i = 0; i < Object.values(files).length; i++) {
          formData.append("file", files[i]);
        }
        formData.append("type", fileType);
        formData.append("order", orderId.toString());

        return {
          url: 'files/',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Files']
    }),

    uploadLeadFile: builder.mutation({
      query: ({ files, leadId }) => {
        const formData = new FormData();
        for (let i = 0; i < Object.values(files).length; i++) {
          formData.append("file", files[i]);
        }
        formData.append("lead", leadId);

        return {
          url: 'files/',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Files']
    }),

    uploadQuoteFile: builder.mutation({
      query: ({ files, quoteId }) => {
        const formData = new FormData();
        for (let i = 0; i < Object.values(files).length; i++) {
          formData.append("file", files[i]);
        }
        formData.append("quote", quoteId);

        return {
          url: 'files/',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Files']
    }),

    updateFile: builder.mutation({
      query: (file) => ({
        url: `files/${file.id}/`,
        method: 'PATCH',
        body: file
      }),
      invalidatesTags: ['Files']
    }),

    deleteFile: builder.mutation({
      query: (id) => ({
        url: `files/${id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Files']
    }),

    getXls: builder.query({
      query: () => 'xls/',
      providesTags: ['Xls']
    }),

    loadXls: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: 'xls/',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Xls']
    }),

    getXlsFileCheck: builder.query({
      query: (id) => `xls-proc?id=${id}`,
      providesTags: ['Xls']
    }),

    applyXlsReportChanges: builder.mutation({
      query: (id) => ({
        url: `xls-proc/apply/${id}/`,
        method: 'POST'
      }),
      invalidatesTags: ['Xls']
    }),
  }),
});

export const {
  useGetFilesQuery,
  useGetLeadFilesQuery,
  useGetQuoteFilesQuery,
  useUploadFileMutation,
  useUploadLeadFileMutation,
  useUploadQuoteFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
  useGetXlsQuery,
  useLoadXlsMutation,
  useGetXlsFileCheckQuery,
  useApplyXlsReportChangesMutation,
} = filesApi;
