import { ChangeEvent, useState } from "react";
import { useGetPublicsQuery } from "entities/publics/model/api";
import { PublicList } from "entities/publics/ui/PublicList";
import Loader from "shared/ui/loader/loader";

type Filters = {
  permanent: boolean;
  shop: boolean;
  linemedia: boolean;
  truck1: boolean;
  model: string;
  vehicle_type: string;
  promo: boolean;
};

type FilterValue = {
  [K in keyof Filters]: Filters[K] extends boolean ? boolean : string;
};

const modelOptions = ['TGE', 'TGX', 'TGS', 'TGL', 'TGM', 'UST', 'SEM'];
const vehicleTypeOptions = ['camion', 'furgone', 'semirimorchio'];
export const PublicsPage = () => {
  const [filters, setFilters] = useState<Filters>({
    permanent: true,
    shop: false,
    linemedia: false,
    truck1: false,
    promo: false,
    model: "",
    vehicle_type: "",
  });

  const searchParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'boolean' && value) {
      searchParams.append(key, "true");
    } else if (typeof value === 'string' && value) {
      searchParams.append(key, value);
    }
  });

  const { data: publicList = [], isLoading, error } = useGetPublicsQuery(searchParams.toString());

  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, type } = event.target;
    const value = type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  if (isLoading) return <Loader />;
  
  if (error) {
    return <div>Error loading publics</div>;
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-2">
        {Object.keys(filters).map((filter) => 
          filter === 'model' ? (
            <label key={filter} className="flex items-center gap-2">
              <select
                value={filters.model}
                onChange={handleFilterChange}
                name={filter}
                className="px-2 py-1 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Scegli modello</option>
                {modelOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-700">
                {filter.charAt(0).toUpperCase() + filter.slice(1)}
              </span>
            </label>
          ) : filter === 'vehicle_type' ? (
            <label key={filter} className="flex items-center gap-2">
              <select
                value={filters.vehicle_type}
                onChange={handleFilterChange}
                name={filter}
                className="px-2 py-1 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Scegli tipo veicolo</option>
                {vehicleTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-700">
                Tipo veicolo
              </span>
            </label>
          ) : (
            <label key={filter} className="flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                checked={filters[filter as keyof FilterValue] as boolean}
                onChange={handleFilterChange}
                name={filter}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">
                {filter.charAt(0).toUpperCase() + filter.slice(1)}
              </span>
            </label>
          )
        )}
      </div>
      <div>Total: {publicList.length}</div>
      <PublicList list={publicList} />
    </div>
  );
};
