import { useState, useEffect } from "react";
import { useGetLeadNamesQuery } from "../model/qapi";

interface LeadNameSearchProps {
  onSelectLead?: (leadName: string) => void;
  resetTrigger?: boolean;
}

export const LeadNameSearch = ({ onSelectLead, resetTrigger }: LeadNameSearchProps) => {
  const [search, setSearch] = useState("");
  const [selectedLead, setSelectedLead] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (resetTrigger) {
      setSearch("");
      setSelectedLead("");
      setIsOpen(false);
    }
  }, [resetTrigger]);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue);
    setIsOpen(true);
    
    if (onSelectLead) {
      onSelectLead(newValue);
    }
  };

  const handleSelectLead = (name: string) => {
    setSelectedLead(name);
    setSearch(name);
    setIsOpen(false);
    
    if (onSelectLead) {
      onSelectLead(name);
    }
  };

  const handleClear = () => {
    setSearch("");
    setSelectedLead("");
    setIsOpen(false);
    
    if (onSelectLead) {
      onSelectLead("");
    }
  };

  const { data: leadNames, isLoading } = useGetLeadNamesQuery(search, { skip: !search });

  return <div className="relative">
    <label htmlFor="lead_name" className="text-xs text-gray-500">
      NOME LEAD
    </label>
    <div className="relative">
      <input
        id="lead_name"
        name="lead_name"
        type="text"
        value={search}
        onChange={(e) => handleFieldChange(e)}
        className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        onFocus={() => search && setIsOpen(true)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(false);
          }
        }}
        autoComplete="off"

      />
      {search && (
        <button
          type="button"
          onClick={handleClear}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          aria-label="Cancella ricerca"
        >
          ×
        </button>
      )}
    </div>

    {isOpen && leadNames && leadNames.length > 0 && (
      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
        {leadNames.map((lead, index) => (
          <div
            key={index}
            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
            onClick={() => handleSelectLead(lead)}
          >
            {lead}
          </div>
        ))}
      </div>
    )}

    {isLoading && (
      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="px-4 py-2 text-gray-500">Caricamento...</div>
      </div>
    )}
  </div>;
};
