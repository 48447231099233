import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow as closeLeadWindow, closeWindow } from "app/windows/windowsSlice";
import { useState } from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { QuoteOrderList } from "./QuoteOrderList";
import { useSearchParams } from "react-router-dom";
//import { updateLead } from "entities/crm/leads/model/api";
import { getRoles } from "entities/viewer/model";
import { useUpdateLeadMutation } from "entities/crm/leads/model/qapi";
import { useGetQuoteQuery } from "../model/rtk-api";
import {
  useUpdateQuoteMutation,
  useCreatePdfQuoteMutation
} from "../model/rtk-api";
import { useSendActionMutation } from "entities/actions/model/actionsApi";


export const QuoteFormWindow = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);

  const [searchParams, setSearchParams] = useSearchParams();
  const quoteId = searchParams.get("quote");

  const { data: quote } = useGetQuoteQuery(quoteId as string);
  const [updateQuoteMutation] = useUpdateQuoteMutation();
  const [createPdfQuoteMutation, { isLoading: isCreatingPdf }] = useCreatePdfQuoteMutation();
  const [updateLead] = useUpdateLeadMutation();

  const [sendAction] = useSendActionMutation();

  const [expire, setExpire] = useState<Dayjs>(
    quote && quote.expire ? dayjs(quote.expire) : dayjs().add(15, "day")
  );

  const [sent, setSent] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [signed, setSigned] = useState(false);
  const [signDate, setSignDate] = useState(dayjs());

  const [note, setNote] = useState(quote && quote.note);

  const sentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSent(event.target.checked);
    sentQuoteHandler();
  };

  const acceptHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  const signHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSigned(event.target.checked);
  };

  const closeHandler = () => {
    dispatch(closeWindow("quoteFormWindow"));
    searchParams.delete("quote");
    setSearchParams(searchParams);
  };

  const saveQuoteHandler = async () => {
    await updateQuoteMutation({
      id: quote?.id,
      expire: expire.format("YYYY-MM-DD"),
      note: note,
    });
    if (quote?.id) {
      await createPdfQuoteMutation(quote.id);
    }
    closeHandler();
  };

  const createPdfQuoteHandler = async () => {
    if (quote?.id) {
      await createPdfQuoteMutation(quote.id);
    }
  }

  const sentQuoteHandler = async () => {
    if (quote?.id) {
      await updateQuoteMutation({
        id: quote.id,
        sent: true,
      });

      if (quote?.lead) {
        await updateLead({
          id: quote.lead,
          status: "decision",
        });
      }
    }
  };

  const acceptQuoteHandler = async () => {
    if (quote?.id) {
      await updateQuoteMutation({
        id: quote.id,
        accepted: dayjs().format("YYYY-MM-DDThh:mm"),
      });

      if (quote?.lead) {
        await updateLead({
          id: quote.lead,
          status: "signing",
        });
          sendAction({
            type: "lead",
            id: quote.lead,
            verb: `ha accetato il preventivo #${quote.id}`,
          })
      }
    }
    setAccepted(false);
  };

  const signedContractHandler = async () => {
    if (signed && quote?.id) {
      await updateQuoteMutation({
        id: quote.id,
        signed: signDate?.format("YYYY-MM-DDThh:mm"),
      });

      if (quote?.lead) {
        await updateLead({
          id: quote.lead,
          status: "signed",
        });
          sendAction({
            type: "lead",
            id: quote.lead,
            verb: `Contratto preventivo #${quote.id} è stato firmato`,
          })
      }

      dispatch(closeLeadWindow("leadDetailsWindow"));
      searchParams.delete("lead");
      setSearchParams(searchParams);
      setSigned(false);
    }
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogContent>
          <div>PREVENTIVO #{quote?.id}</div>
          <Stack spacing={2} marginTop={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {quote && <QuoteOrderList quoteId={quote.id} />}

              <div style={{ fontWeight: "normal" }}>
                PREZZO TOTALE PREVENTIVO: €{" "}
                {quote?.total_price?.toLocaleString()}
              </div>
              {quote?.editable ? (
                <TextField
                  size="small"
                  value={note}
                  label="EVENTUALI NOTE"
                  multiline
                  rows={2}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNote(event.target.value);
                  }}
                />
              ) : (
                <>{quote?.note && <div>EVENTUALI NOTE: {note}</div>}</>
              )}
              <div style={{ marginLeft: "auto", marginRight: 0 }}>
                <Stack direction="column" alignItems="end">
                  {quote?.editable && (
                    <div style={{ width: "150px" }}>
                      <DatePicker
                        label="Scadenza"
                        value={expire}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => setExpire(dayjs(newValue))}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </div>
                  )}
                  {quote?.file_path && <a href={quote.file_path} className="text-blue-500 mt-1" target="_blank">PDF</a>}
                  {quote?.orders && quote?.orders.length > 0 && <>
                    {!quote?.sent && !quote?.accepted && !quote?.signed && (
                      <FormGroup>
                        <FormControlLabel
                          id="to_send"
                          control={
                            <Checkbox checked={sent} onChange={sentHandler} />
                          }
                          label="PREVENTIVO COMUNICATO"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    )}
                    {quote?.sent && (
                      <div
                        id="sent"
                        style={{
                          color: "darkgreen",
                          fontWeight: "normal",
                          textAlign: "right",
                          marginTop: "10px",
                        }}
                      >
                        COMUNICATO
                      </div>
                    )}

                    {quote?.sent &&
                      !quote?.accepted &&
                      quote?.status !== "non valido" && (
                        <FormGroup>
                          <FormControlLabel
                            id="to_accept"
                            control={
                              <Checkbox
                                checked={accepted}
                                onChange={acceptHandler}
                              />
                            }
                            label="IL CLIENTE HA ACCETTATO IL PREVENTIVO"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      )}
                    {quote?.accepted && (
                      <div
                        id="accepted"
                        style={{
                          color: "darkgreen",
                          fontWeight: "normal",
                          textAlign: "right",
                        }}
                      >
                        ACCETTATO {dayjs(quote?.accepted).format("DD/MM/YYYY")}{" "}
                      </div>
                    )}
                    {!quote?.signed &&
                      quote?.accepted &&
                      roles?.includes("crm_manager") && (
                        <FormGroup>
                          <FormControlLabel
                            id="to_sign"
                            control={
                              <Checkbox checked={signed} onChange={signHandler} />
                            }
                            label="CONTRATTO FIRMATO"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      )}
                    {quote?.signed && (
                      <div
                        id="signed"
                        style={{
                          color: "darkgreen",
                          fontWeight: "normal",
                          textAlign: "right",
                        }}
                      >
                        CONTRATTO FIRMATO{" "}
                        {dayjs(quote?.accepted).format("DD/MM/YYYY")}{" "}
                      </div>
                    )}
                  </>}
                </Stack>
              </div>
            </LocalizationProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => createPdfQuoteHandler()}>{isCreatingPdf ? "Creazione PDF..." : "Crea PDF"}</Button>
          <Button variant="outlined" onClick={() => closeHandler()}>CHIUDI</Button>

          {quote?.editable && (
            <Button variant="outlined" onClick={() => saveQuoteHandler()}>Salva</Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog id="accept_confirm" open={accepted}>
        <DialogContent>
          Il preventivo sarà accettato e non sarà più modificabile.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAccepted(false)}>Indietro</Button>
          <Button id="accept_ok_button" onClick={() => acceptQuoteHandler()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={signed} fullWidth maxWidth="xs">
        <DialogContent>
          Gli ordini del preventivo passano allo stato VENDUTO.
          <div style={{ marginTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data firma contratto"
                value={signDate}
                inputFormat="DD/MM/YYYY"
                onChange={(newValue) => setSignDate(dayjs(newValue))}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSigned(false)}>Indietro</Button>
          <Button id="sign_ok_button" onClick={() => signedContractHandler()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
