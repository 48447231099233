import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import { sort } from "fast-sort";
import { InvoicesList } from "./InvoicesList";
import { useGetInvoicesQuery } from "../model/api";
import { Invoice } from "../model/types";
import Loader from "shared/ui/loader";


const orderingProps = {
  dt_doc: ["Data fattura", "desc"],
  expire: ["Scadenza fattura", "asc"],
  loan_expire: ["Scadenza banca", "asc"],
  paid: ["Data pagamento", "desc"],
  paid_loan: ["Data pagamento banca", "desc"],
};

export const Invoices = () => {
  const [searchString, setSearchString] = useState("");
  const [sorting, setSorting] = useState(Object.keys(orderingProps)[0]);
  const [showActive, setShowActive] = useState(true);
  const [showLoans, setShowLoans] = useState(true);
  const [showNormals, setShowNormals] = useState(true);
  const [bank, setBank] = useState<string | null>(null);
  const [vw, setVw] = useState(false);

  // INVOICES
  const { data, isLoading} =
    useGetInvoicesQuery("");
  const list = data ? data : [];

  //const list = useAppSelector((state) => state.invoices.entities);
  const sorted =
    orderingProps[sorting as keyof typeof orderingProps][1] === "desc"
      ? sort(list).desc(sorting as keyof Invoice)
      : sort(list).asc(sorting as keyof Invoice);
  const filtered = sorted.filter(
    (item) =>
      ((showActive && (!item.paid || (item.loan && !item.loan_paid))) ||
        !showActive) &&
      ((!showLoans && !item.loan) || showLoans) &&
      ((!showNormals && item.loan) || showNormals) &&
      (item.bank === bank || bank === null) &&
      (item.vw_assigned === vw || vw === false) &&
      (item.desc.toLowerCase().includes(searchString.toLowerCase()) ||
        searchString === "")
  );
  const bankList = Array.from(new Set(list.map((item) => item.bank)));

  const summa = filtered.reduce((acc, item) => {
    return acc + item.summa_total;
  }, 0);

  useEffect(() => {
    if (showLoans && !showNormals) setSorting("loan_expire");
    if (!showLoans && showNormals) setSorting("expire");
    if (showLoans && showNormals) setSorting("dt_doc");
  }, [showLoans, showNormals]);

  if (isLoading) return <Loader />;

  return (
    <Stack direction="row" spacing={3} marginTop={3}>
      <Stack sx={{ width: "300px" }} spacing={2}>
        <Stack direction="row" alignItems="center">
          <div>Mostra solo attivi</div>
          <Checkbox
            checked={showActive}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setShowActive(event.target.checked);
              setBank(null);
              setShowLoans(true);
              setShowNormals(true);
            }}
          />
        </Stack>
        {showActive && (
          <Stack direction="row">
            <Stack direction="row" alignItems="center">
              <div>ANTICIPATI</div>
              <Checkbox
                checked={showLoans}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShowLoans(event.target.checked);
                  event.target.checked && setSorting("loan_expire");
                  setBank(null);
                  setVw(false);
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <div>NORMALI</div>
              <Checkbox
                checked={showNormals}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShowNormals(event.target.checked);
                  setBank(null);
                  setVw(false);
                }}
              />
            </Stack>
          </Stack>
        )}

        {showLoans && !showNormals && (
          <FormControl variant="outlined" size="small">
            <InputLabel id="banca">Banca</InputLabel>
            <Select
              size="small"
              value={bank}
              label="Banca"
              onChange={(e) => setBank(e.target.value)}
            >
              {bankList.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showNormals && !showLoans && (
          <Stack direction="row" alignItems="center">
            <div>Ceduti VW</div>
            <Checkbox
              checked={vw}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVw(event.target.checked);
              }}
            />
          </Stack>
        )}

        <TextField
          size="small"
          value={searchString}
          label="Ricerca"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
          }}
          helperText="AUFTRAG o model TGX, TGS ecc.."
        />

        <FormControl variant="outlined" size="small">
          <InputLabel id="banca">Ordinamento</InputLabel>
          <Select
            size="small"
            value={sorting}
            label="Ordinamento"
            onChange={(e) => setSorting(e.target.value)}
          >
            {Object.keys(orderingProps).map((key) => (
              <MenuItem key={key} value={key}>
                {orderingProps[key as keyof typeof orderingProps][0]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showNormals && <div>SUMMA: € {summa.toLocaleString("it-IT")}</div>}
      </Stack>
      <InvoicesList list={filtered} showDesc />
      <Stack spacing={1}></Stack>
    </Stack>
  );
};
