import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import "./lead.modules.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { OriginIcon } from "./OriginIcon";
import { leadStatuses, origins, results } from "../model/constants";
import { LeadDescription } from "./LeadDescription";
import EditIcon from "@mui/icons-material/Edit";
import { LeadForm } from "./LeadForm";
import { LeadActionsHistory } from "./LeadActionsHistory";
import { LeadActions } from "./LeadActions";
import {
  openWindow,
  closeWindow,
} from "app/windows/windowsSlice";
import { LeadComments } from "./LeadComments";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { sendEmail } from "shared/sendEmail";
import { LeadQuotes } from "../../../quotes/ui/LeadQuotes";
import { ContactCard } from "entities/crm/contacts/ui/ContactCard";
import { CompanyCard } from "entities/crm/companies/ui/CompanyCard";
import { useGetLeadByIdQuery, useUpdateLeadMutation } from "../model/qapi";
import {
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetManagersQuery } from "entities/managers/model/rtk-api";
import { useCreateCommentMutation } from "entities/comments/model/rtkapi";
import { useGetActionsQuery, useSendActionMutation } from "entities/actions/model/actionsApi";
import { Action } from "entities/actions/model/types";

export const LeadDetails = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const leadId = searchParams.get("lead");
  const { data: lead } = useGetLeadByIdQuery(leadId ? leadId : "", {
    skip: !leadId,
  });
  const [updateLead] = useUpdateLeadMutation();
  const { data: actionsList } = useGetActionsQuery({ leadId: leadId ? leadId : "" });

  const actions = actionsList ? actionsList
        .slice()
        .sort(
          (a: Action, b: Action) =>
            Number(new Date(a.timestamp)) - Number(new Date(b.timestamp))
        )
    : [];

  const [sendAction] = useSendActionMutation();
 
  const userId = useAppSelector((state) => state.viewer.user?.id);
  const resultsList = Object.values(results);
  const [closeStatus, setCloseStatus] = useState(resultsList[0]);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [resultDesc, setResultDesc] = useState("");

  const closeConfirmHandler = () => {
    const resultStatus = Object.keys(results).find(
      (key) => results[key as keyof typeof results] === closeStatus
    );
    if (lead && resultStatus && userId)
      updateLead({
        id: lead.id,
        result: resultStatus,
        user: userId,
        result_desc: resultDesc,
        dt_closed: dayjs().format("YYYY-MM-DD"),
      });

    setCloseConfirm(false);
    setCloseStatus("");
  };

  const closeLeadHandler = () => {
    setCloseConfirm(true);
  };
  // END CLOSE LEAD

  // TRANSFER
  const user = useAppSelector((state) => state.viewer.user);
  const { data: managers } = useGetManagersQuery();
  const managersList = managers ? Object.values(managers).filter(
    (item) => item.seller === true
  ) : [];
  const [transferDialog, setTransferDialog] = useState(false);
  const [manager, setManager] = useState<string | null>(null);
  const [comment, setComment] = useState("");
  const [createComment] = useCreateCommentMutation();

  const changeManagerHandle = (event: SelectChangeEvent) => {
    setManager(event.target.value);
  };
  const changeCommentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  const closeTransfertHandler = () => {
    setTransferDialog(false);
    setComment("");
    setManager(null);
  };

  const makeTransferHandler = () => {
    if (!lead || !manager || !managers) return;

    updateLead({ id: lead.id, user: manager });
    closeTransfertHandler();

    if (comment) {
      createComment({
        text: comment,
        lead: lead.id,
        order: null,
      });
    }

    const selectedManager = managers[Number(manager)];
    if (selectedManager && user) {
      dispatch(
        sendEmail({
          subject: "Nuovo lead assegnato",
          message: `${user.first_name} ${user.last_name} ha trasferito a te il lead ${window.location.origin}/crm/?lead=${lead.id}
Commento: ${comment}`,
          to: selectedManager.email,
          from: "alpiapp@alpiservice.net",
          template: "seller_email",
        })
      );
    }
  };
  // END TRANSFER

  // PAUSED

  const [pauseDialog, setPauseDialog] = useState(false);
  const [nextContact, setNextContact] = useState<Dayjs | null>(null);

  const closePausedHandler = () => {
    setPauseDialog(false);
    setNextContact(null);
  };

  const pausedHandler = () => {
    if (lead) {
      updateLead({
        id: lead.id,
        dt_next_contact: nextContact ? nextContact.format("YYYY-MM-DD") : null,
      });

        sendAction({
          type: "lead",
          id: lead.id,
          verb: `ha sospeso il lead fino ${nextContact?.format("DD/MM/YYYY")}`,
        })
      closePausedHandler();
    }
  };
  // END PAUSED

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const [leadFormDialog, setLeadFormDialog] = useState(false);



  const closeHandler = () => {
    if (lead) {
      updateLead({
        id: lead.id,
      });
    }
    searchParams.delete("lead");
    setSearchParams(searchParams);
    dispatch(closeWindow("leadDetailsWindow"));
  };

  const openOrderWindowHandler = () => {
    if (lead) searchParams.set("order", lead.ref);
    setSearchParams(searchParams);
    if (lead) 
    dispatch(openWindow("newOrderWindow"));
  };

  const openAddCompanyToLeadHandler = () => {
   dispatch(openWindow("addCompanyToLead"));
    //dispatch(setCurrentLead(lead.id));
  };

  if (lead)
    return (
      <>
        <Dialog
          open={true}
          fullWidth
          maxWidth="lg"
          fullScreen={xsScreen ? true : false}
        >
          <DialogContent>
            <Stack
              sx={{ flexDirection: { xs: "column", md: "row" }, gap: "10px" }}
            >
              <Stack
                direction="column"
                spacing="10px"
                sx={{ width: { md: "40%" } }}
              >
                <div className="block">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <OriginIcon origin={lead.origin} size="10px" />
                    <div>
                      {(lead.status &&
                        leadStatuses[
                          lead.status as keyof typeof leadStatuses
                        ]?.title.toUpperCase()) || ["Nessun status"]}
                    </div>
                  </div>

                  {lead.dt_next_contact &&
                    dayjs(lead.dt_next_contact) > dayjs() && (
                      <div style={{ marginTop: "10px", color: "darkred" }}>
                        SOSPESO FINO:{" "}
                        {dayjs(lead.dt_next_contact).format("DD/MM/YYYY")}
                      </div>
                    )}

                  {lead.result && (
                    <div style={{ marginTop: "10px", color: "darkred" }}>
                      CHIUSO: {results[lead.result as keyof typeof results]} -{" "}
                      {lead.result_desc}
                    </div>
                  )}

                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>

                    <div>Nome lead: {lead.name}</div>
                    <div>
                      Creato:{" "}
                      {dayjs(lead.created).format("DD/MM/YYYY [alle] HH:mm")}
                    </div>
                    <div>origin: {origins[lead.origin as keyof typeof origins]?.title}</div>
                    <div>
                      Venditore:{" "}
                      {lead.username ? lead.username : "ancora libero"}
                    </div>
                  </div>

                  <div>
                    Descrizione:{" "}
                    {lead.origin === "subito_message" ? (
                      <LeadDescription lead={lead} />
                    ) : (
                      <div>{lead.description}</div>
                    )}
                    {lead.ref && (
                      <div
                        style={{
                          color: "white",
                          margin: "5px 0 15px 0",
                          padding: "3px",
                          backgroundColor: "darkblue",
                          width: "auto",
                          textAlign: "center",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => openOrderWindowHandler()}
                      >
                        ORDINE ref:{lead.ref}
                      </div>
                    )}
                  </div>
                  {lead.price !== "0" && (
                    <div>Prezzo comunicato: {lead.price}</div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "grey",
                      border: "2px solid grey",
                      padding: "5px",
                      borderRadius: "15px",
                      textAlign: "center",
                    }}
                  >
                    {lead.id}
                  </div>
                  <EditIcon
                    className="edit_icon"
                    sx={{ fontSize: "16px", marginTop: "5px" }}
                    onClick={() => setLeadFormDialog(true)}
                  />
                  <LeadForm
                    isOpen={leadFormDialog}
                    setIsOpen={setLeadFormDialog}
                    currentLead={lead}
                  />
                </div>
                <ContactCard contactId={lead.contact} />
                {!lead.contact && (
                  <AddCircleOutlineIcon
                    onClick={() => openAddCompanyToLeadHandler()}
                  />
                )}
                <CompanyCard companyId={lead.company} />
                {!lead.company && (
                  <AddCircleOutlineIcon
                    onClick={() => openAddCompanyToLeadHandler()}
                  />
                )}
                {/* <Button
                  variant="outlined"
                  onClick={() => dispatch(openLeadWindow("leadFilesWindow"))}
                >
                  <FolderOpenIcon />
                </Button> */}

                <Button
                  variant="outlined"
                  onClick={() => setTransferDialog(true)}
                >
                  TRASFERIRE
                </Button>
                <Button variant="outlined" onClick={() => setPauseDialog(true)}>
                  SOSPENDERE
                </Button>
                <Button variant="contained" onClick={() => closeLeadHandler()}>
                  LEAD PERSO
                </Button>
              </Stack>

              {/* right panel - Actions */}
              <Stack width="100%" direction="column" spacing="10px">
                <LeadActionsHistory list={actions} />
                <LeadComments leadId={lead.id} />
                <LeadQuotes leadId={lead.id} />
                <LeadActions leadId={lead.id} />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeHandler()}>Chiudi</Button>
          </DialogActions>
        </Dialog>

        {/* ---------------- TRANSFERT ----------------- */}
        <Dialog open={transferDialog} fullWidth maxWidth="sm">
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="seller-select-label">Venditore</InputLabel>
              <Select
                labelId="seller-select-label"
                label="Venditore"
                value={manager || ""}
                onChange={changeManagerHandle}
              >
                {managersList.map((manager) => (
                  <MenuItem key={manager.id} value={manager.user}>
                    {manager.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ marginTop: "10px" }}
              fullWidth
              label="Commento"
              multiline
              rows={3}
              value={comment}
              onChange={changeCommentHandler}
            />
            {manager && managers && managers[Number(manager)] && (
              <div style={{ marginTop: "10px", textAlign: "center" }}>
                Dopo trasferimento sarà inviata una notifica a{" "}
                {managers[Number(manager)].name}.
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeTransfertHandler}>Indietro</Button>
            <Button
              onClick={makeTransferHandler}
              disabled={!manager || !managers || !managers[Number(manager)]}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* ---------------- PAUSE ----------------- */}
        <Dialog open={pauseDialog} fullWidth maxWidth="sm">
          <DialogContent>
            <Stack direction="row" alignItems="center" spacing={2}>
              {/* <CalendarMonthIcon /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="SOSPESO FINO"
                  value={nextContact}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => setNextContact(dayjs(newValue))}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <div style={{ marginTop: "10px" }}>
              O lascia vuoto per togliere la sospensione
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePausedHandler()}>Indietro </Button>

            <Button variant="contained" onClick={() => pausedHandler()}>
              imposta{" "}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={closeConfirm}>
          <DialogContent>
            <Stack spacing={2} sx={{ width: "200px" }}>
              <p>Vorresti chiudere questo LEAD? Scegli la causa.</p>
              <Select
                value={closeStatus}
                fullWidth
                onChange={(e) => setCloseStatus(e.target.value)}
              >
                {resultsList.map((result, index) => (
                  <MenuItem key={index} value={result}>
                    {result.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              {closeStatus === "altro" && (
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    multiline
                    maxRows={3}
                    label="Descrivi la causa"
                    value={resultDesc}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setResultDesc(event.target.value);
                    }}
                  />
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCloseConfirm(false)}>NO</Button>
            <Button variant="contained" onClick={() => closeConfirmHandler()}>
              Si
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  return <></>;
};
