import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Document, Page, pdfjs } from "react-pdf";
import { useAppSelector } from "app/hooks";
import { getViewer } from "entities/viewer/model";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useState } from "react";
import { useDeleteFileMutation } from "../model/rtk-api";
import { OrderFile } from "../model/types";



pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface FileItemProps {
  item: OrderFile;
  managed?: boolean;
}
export const FileItem = ({ item, managed = true }: FileItemProps) => {
  const [del, setDel] = useState(false);
  const user = useAppSelector(getViewer);
  const [deleteFile] = useDeleteFileMutation();
  const handleDelete = (item: OrderFile) => {
    deleteFile(item.id);
    setDel(false);
  };

  const downloadHandler = () => {
    fetch(item.file).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = item.name;
        alink.click();
      });
    });
  };

  // const getAllText = async (pdf: any) => {
  //   try {
  //     const numPages = pdf.numPages;
  //     let fullText = '';

  //     for (let pageNum = 1; pageNum <= numPages; pageNum++) {
  //       const page = await pdf.getPage(pageNum);
  //       const textContent = await page.getTextContent();
  //       fullText += textContent.items.map((item: any) => item.str).join(' ') + ' ';
  //     }
  //     extractInfo(fullText);
  //   } catch (error) {
  //     console.error("Error extracting text from PDF:", error);
  //   }
  // };

  // const downloadAndExtractText = async () => {
  //   const response = await fetch(item.file);
  //   const blob = await response.blob();
  //   const pdf = await pdfjs.getDocument(URL.createObjectURL(blob)).promise;
  //   await getAllText(pdf);

  // };

  // const extractInfo = async (text: string) => {
  //   axios.post("api/ai/extract-info/", { text: text }, {
  //     headers: {
  //       "Authorization": `token ${localStorage.getItem("token")}`
  //     }
  //   })
  //   .then((response) => {
  //     console.log(response);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  // };

  return (
    <>
      <Stack
        key={item.id}
        direction="column"
        alignItems="center"
        sx={{ position: "relative", marginTop: 3 }}
      >
        <Paper
          sx={{
            border: "1px solid lightgrey",
            height: "160px",
            width: "120px",
            margin: "10px",
          }}
        >
          <Stack direction="column">
            <a
              key={item.id}
              href={item.file}
              target="_blank"
              rel="noreferrer"
              style={{ position: "relative" }}
            >
              {(() => {
                // Ottieni l'estensione del file
                const fileExtension = item.name.split('.').pop()?.toUpperCase();
                const isPdf = fileExtension === 'PDF';
                
                // Componente per mostrare il tipo del file
                const TypeBadge = () => item.type !== "other" ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "darkblue",
                      padding: "5px",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "5px",
                      textOverflow: "ellipsis",
                      width: "85%",
                    }}
                  >
                    {item.type?.toUpperCase()}
                  </div>
                ) : null;
                
                if (isPdf) {
                  return (
                    <>
                      <Document file={item.file}>
                        <Page pageIndex={0} height={150} renderTextLayer={true} />
                      </Document>
                      <TypeBadge />
                    </>
                  );
                } else {
                  return (
                    <div style={{ 
                      height: "150px", 
                      display: "flex", 
                      alignItems: "center", 
                      justifyContent: "center",
                      position: "relative"
                    }}>
                      <Typography 
                        variant="h4" 
                        sx={{ 
                          fontWeight: "bold",
                          color: "darkblue"
                        }}
                      >
                        {fileExtension}
                      </Typography>
                      <TypeBadge />
                    </div>
                  );
                }
              })()}
            </a>
          </Stack>
        </Paper>
        <Typography sx={{ fontSize: "10px" }}>
          {item.name.substring(0, 20)}{" "}
        </Typography>
        {managed && (
          <Stack marginTop={1} direction="row" alignItems="center" spacing={2}>
            <IconButton onClick={() => downloadHandler()}>
              <DownloadForOfflineIcon
                sx={{
                  position: "absolute",
                  right: "-5px",
                  top: "-5px",
                  width: "20px",
                  color: "darkblue",
                }}
              />
            </IconButton>

            {(item.owner === user?.id || user?.roles.includes("admin")) && (
              <>
                <IconButton onClick={() => setDel(true)}>
                  <HighlightOffIcon
                    sx={{
                      position: "absolute",
                      right: "-5px",
                      top: "-5px",
                      width: "20px",
                      color: "darkred",
                    }}
                  />
                </IconButton>
              </>
            )}

            {/* <IconButton onClick={() => downloadAndExtractText()}><AutoAwesomeIcon /></IconButton> */}
          </Stack>
        )}
      </Stack>

      <Dialog open={del}>
        <DialogContent>
          Sei sicuro di voler eliminare questo file?
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setDel(false)}>
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDelete(item)}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
