import { Button } from "@mui/material";
import { FileAnalyzer } from "./FileAnalyzer";
import { useState } from "react";

export const UploadDocPage = () => {
  const [AnalyzerIsOpen, analyzerSetIsOpen] = useState(false);
  return (
    <div >
      <pre className="my-4">Carica uno dei seguenti file:<br></br>
        - Ordine

      </pre>
      <Button variant="outlined" onClick={() => analyzerSetIsOpen(true)}>Riconoscimento file</Button>
      <FileAnalyzer isOpen={AnalyzerIsOpen} onClose={() => analyzerSetIsOpen(false)} />
    </div>
  );
};
