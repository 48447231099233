import {
  DndContext,
  closestCenter,
  useSensors,
  useSensor,
  PointerSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";

import { CircularProgress, IconButton, Paper } from "@mui/material";
import { ChangeEvent } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ImageCard } from "./ImageCard";
import { useGetPhotosQuery, useUpdatePhotosOrderMutation, useUploadPhotosMutation } from "../model/api";
import { Photo } from "../model/types";

interface Props {
  publicId: string;
}

export const PhotoManager = ({ publicId }: Props) => {
  const { data: imageList } = useGetPhotosQuery(publicId);
  const [uploadPhotos, { isLoading: uploading }] = useUploadPhotosMutation();
  const [updatePhotosOrder] = useUpdatePhotosOrderMutation();
  const imagesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadPhotos({ files: event.target.files, publicId });
    }
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id && imageList) {
      const new_arr = (items: Photo[]) => {
        const activeIndex = items.findIndex(
          (item: Photo) => item.id === active.id
        );
        const overIndex = items.findIndex((item: Photo) => item.id === over.id);
        return arrayMove(items, activeIndex, overIndex);
      };
      const newOrder = new_arr(imageList);
      updatePhotosOrder(newOrder.map((photo, index) => ({ id: photo.id, sequence: index })));
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  return (
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={imageList ?? []} strategy={rectSortingStrategy}>
          {imageList?.map((item, index) => (
            <ImageCard key={item.id} image={item} index={index} />
          ))}
        </SortableContext>
      </DndContext>

      {uploading && (
        <Paper
          elevation={5}
          sx={{
            height: "150px",
            width: "200px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px",
          }}
        >
          <CircularProgress color="primary" />
        </Paper>
      )}

      <Paper
        elevation={5}
        sx={{
          height: "150px",
          width: "200px",
          textAlign: "center",
          lineHeight: "150px",
          margin: "5px",
        }}
      >
        <IconButton component="label">
          <input
            hidden
            accept="image/jpeg"
            multiple
            type="file"
            onChange={imagesHandler}
          />
          <AddCircleOutlineIcon sx={{ fontSize: "40px" }} />
        </IconButton>
      </Paper>
    </div>
  );
};
