import { Paper } from "@mui/material";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import styles from "./ImageCard.module.css";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDeletePhotoMutation } from "../model/api";
import { Photo } from "../model/types";
export interface ImageCardProps {
  image: Photo;
  index: number;
}

export const ImageCard = ({ image, index }: ImageCardProps) => {
  const [deletePhoto] = useDeletePhotoMutation();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const imgDeleteHandler = (id: string) => {
    deletePhoto(id);
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Paper
        className={styles.paper}
        elevation={5}
        sx={{
          height: "150px",
          width: "200px",
          position: "relative",
          marginX: "10px",
          marginY: "5px",
        }}
      >
        <img
          src={`${image.image}`}
          alt="alt"
          loading="lazy"
          className={styles.img}
        />
        <div
          className={styles.delete}
          onClick={() => imgDeleteHandler(image.id)}
        >
          <HighlightOffSharpIcon />
        </div>
        <div className={styles.order}>{index + 1}</div>
      </Paper>
    </div>
  );
};
