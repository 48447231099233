import { Button } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Photo } from "../model/types";

interface PhotoListProps {
  photos: Photo[];
}

export const PhotoList = ({ photos }: PhotoListProps) => {
  const downloadHandler = (item: Photo) => {
    fetch(item.image, {
      cache: "no-store",
    }).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = item.id + Math.floor(Math.random() * 1000000000);
        alink.click();
      });
    });
  };

  const downloadAllHandler = () => {
    photos.forEach((item) => {
      fetch(item.image).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = item.id + Math.floor(Math.random() * 1000000000);
          alink.click();
        });
      });
    });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        {photos.map((item) => (
          <div key={item.id}>
            <img
              style={{ width: "250px" }}
              srcSet={`${item.image}`}
              src={`${item.image}`}
              alt={item.id}
            />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => downloadHandler(item)}
            >
              <DownloadForOfflineIcon sx={{ color: "darkblue" }} />
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          onClick={() => downloadAllHandler()}
        >
          Scarica tutti
        </Button>
        <Button
          sx={{ marginTop: "20px" }}
          variant="outlined"
          onClick={() => window.close()}
        >
          Chiudi
        </Button>
      </div>
    </div>
  );
};
