import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Order } from "types/order";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/orders",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["orders", "order", "ManOrders"],
  endpoints: (builder) => ({
    getOrderById: builder.query<Order, string | null>({
      query: (id) => id ? `${id}` : '',
      providesTags: (result, error, id) => [{ type: 'order', id: id || '' }],
    }),
    
    getOrders: builder.query<Order[], void>({
      query: () => '',
      providesTags: ['orders'],
    }),

    getHistory: builder.query({
      query: () => `/view_history`,
      providesTags: ["orders"],
    }),
    
    updateOrder: builder.mutation<Order, Partial<Order>>({
      query: (order) => ({
        url: `/${order.id}/`,
        method: "PUT",
        body: order,
      }),
      invalidatesTags: (result, error, order) => [
        { type: 'order', id: order.id },
        'orders',
        'ManOrders'
      ],
    }),

    getOrdersByIds: builder.query<Order[], string[]>({
      query: (ids) => ({
        url: '/get_orders_by_ids',
        params: { ids: ids.join(',') }
      }),
      providesTags: (result) => 
        result ? result.map(order => ({ type: 'order' as const, id: order.id })) : [],
    }),

    getOrdersByCode: builder.query<Order[], string[]>({
      query: (codes) => ({
        url: '/get_orders_by_code',
        params: { codes: codes.join(',') }
      }),
    }),

    getOrderHistory: builder.query({
      query: (orderId: string) => ({
        url: `/get_order_history`,
        params: { order: orderId }
      }),
      providesTags: (result, error, orderId) => [{ type: 'order', id: orderId }],
    }),
  }),
});

export const {
  useGetOrderByIdQuery,
  useGetOrdersQuery,
  useUpdateOrderMutation,
  useGetHistoryQuery,
  useGetOrdersByIdsQuery,
  useGetOrderHistoryQuery,
  useGetOrdersByCodeQuery,
} = ordersApi;
