import { useGetPublicByOrderIdQuery } from "entities/publics/model/api";
import { PhotoList } from "entities/publics/ui/PhotoList";
import { useParams } from "react-router-dom";

export const OrderPhotoPage = () => {
  const orderId = useParams().orderId;
  const { data: publics } = useGetPublicByOrderIdQuery(orderId ?? '', {
    skip: !orderId,
  });
  if (publics && publics.photos && publics.photos.length > 0)
    return <PhotoList photos={publics.photos} />;
  return <>Non ci sono le foto per questo ordine</>;
};
