import { useAppDispatch } from "app/hooks";
import { Contact } from "../model/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAddContactMutation,
  useGetContactByIdQuery,
  useUpdateContactMutation,
} from "../model/rtkapi";
import { closeWindow } from "app/windows/windowsSlice";

export const ContactFormWindow = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const contactId = searchParams.get("contact");
  const {
    data: currentContact,
    error,
    isLoading,
    refetch,
  } = useGetContactByIdQuery(Number(contactId), { skip: !contactId });
  const [updateContact, { error: addUserError, isLoading: isAddingUser }] =
    useUpdateContactMutation();

  const newContact: Contact = {
    id: "",
    name: "",
    label: "",
    phone: "",
    email: "",
    wa: false,
  };

  const [contact, setContact] = useState(
    currentContact ? currentContact : newContact
  );

  useEffect(() => {
    if (currentContact) setContact(currentContact);
  }, [currentContact]);

  const handleChangeContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContact({ ...contact, [name]: value });
  };

  const closeHandler = () => {
    dispatch(closeWindow("contactFormWindow"));
    setContact(newContact);
    searchParams.delete("contact");
    setSearchParams(searchParams);
  };

  const [addContact] = useAddContactMutation();

  const updateHandler = () => {
    if (contact.id !== "") updateContact(contact);
    else {
      addContact(contact);
    }
    closeHandler();
  };

  return (
    <div>
      <Dialog open={true} fullWidth>
        <DialogContent>
          {!currentContact ? (
            <>
              <h4>AGGIUNGERE CONTATTO</h4>
            </>
          ) : (
            <>
              <h4>MODIFICA CONTATTO</h4>
              <p>
                Attenzione! Se si cambia i dati qua si cambieranno anche per gli
                altri lead con questo contatto. Se vuoi collegare un altro
                contatto o creare un nuovo contatto vai su impostazioni del
                Lead.
              </p>
            </>
          )}

          <Stack direction="column" spacing={2}>
            <TextField
              size="small"
              defaultValue={contact.name}
              label="Nome"
              name="name"
              onChange={handleChangeContact}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            <TextField
              size="small"
              defaultValue={contact.phone}
              label="Telefono"
              name="phone"
              onChange={handleChangeContact}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            <TextField
              size="small"
              defaultValue={contact.email}
              label="Email"
              name="email"
              onChange={handleChangeContact}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeHandler()}>Chiudi</Button>
          <Button onClick={() => updateHandler()}>Salva</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
