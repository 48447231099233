import { Box } from "@mui/material";
import { Vehicle } from "../api/types";
import { VehicleCard } from "./VehichleCard";

interface Props {
  title: string;
  status: string;
  owner?: string;
  display?: boolean;
  vehicles: Array<Vehicle>;
}

export const VehicleList = ({
  title,
  status,
  owner,
  vehicles,
  display = true,
}: Props) => {
  const list = vehicles
    .filter(
      (item) =>
        item.status === status 
      // &&
      //   (item.seller_name === owner || owner === "TUTTI")
    )
    .sort(
      (a, b) => Number(new Date(b.modified)) - Number(new Date(a.modified))
    );

  return (
    <>
      <Box
        className="lead_list"
        sx={{ display: { xs: display ? "flex" : "none", md: "flex" } }}
      >
        <div>
          {title.toUpperCase()}{" "}
          <span style={{ color: "lightblue" }}>{list.length}</span>
        </div>
        {list.map((item) => (
          <div
            key={item.id}
            style={{
              //border: last === item.id ? "2px dotted orange" : "none",
              boxSizing: "border-box",
              padding: "1px",
              width: "100%",
            }}
          >
            <VehicleCard vehicle={item} />
          </div>
        ))}
      </Box>
    </>
  );
};
