import { useAppDispatch, useAppSelector } from "app/hooks";
import {  Divider } from "@mui/material";
import { ServiceCard } from "./ServiceCard";
import { ServiceFormWindow } from "./ServiceFormWindow";
import { ServiceCreateWindow } from "./ServiceCreateWindow";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ServiceRow } from "./ServiceRow";
import { ServiceCalc } from "../model/calc";
import { 

  useGetServicesQuery 
} from "../model/rtk-api";
import { openWindow } from "app/windows/windowsSlice";

interface Props {
  quoteId: string;
  orderId: string;
  editable: boolean;
}

export const ServiceList = ({ quoteId, orderId, editable }: Props) => {
  const dispatch = useAppDispatch();
  const serviceCreateWindow = useAppSelector(
    (state) => state.windows.serviceCreateWindow
  );

  // Используем RTK Query хуки
  const { data: services = [], isLoading } = useGetServicesQuery({ 
    quoteId, 
    orderId 
  });

  // Фильтруем сервисы по orderId
  const serviceList = services.filter((item) => item.order === orderId);

  const createServiceHandler = () => {
    dispatch(openWindow("serviceCreateWindow"));
   // dispatch(setCurrentOrder(orderId));
  };

  const totalCost = serviceList.reduce((acc, item) => {
    return acc + item.cost;
  }, 0);

  const totalPrice = serviceList.reduce((acc, item) => {
    return acc + item.price;
  }, 0);

  const totalProfit = serviceList.reduce((acc, item) => {
    return acc + new ServiceCalc(item).calcProfit();
  }, 0);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div style={{ border: "0px dotted grey", padding: "10px" }}>
      <ServiceRow
        list={["", "", "COSTO", "MARGINE", "MARG €", "PREZZO"]}
        fontSize="0.7rem"
        fontWeight="bold"
      />
      <Divider />

      <div>
        {serviceList.map((item) => (
          <ServiceCard 
            key={item.id} 
            serviceId={item.id} 
            editable={editable} 
          />
        ))}
      </div>
      {editable && (
        <div
          style={{
            textAlign: "left",
            marginTop: "0.2rem",
            color: "grey",
            cursor: "pointer",
          }}
          onClick={createServiceHandler}
        >
          <AddBoxIcon />
        </div>
      )}
      <Divider />
      <ServiceRow
        list={[
          "TOTALE",
          "",
          totalCost.toString(),
          "",
          Math.round(totalProfit).toString(),
          totalPrice.toString(),
        ]}
        fontWeight="normal"
      />
      {serviceCreateWindow && (
        <ServiceCreateWindow 
          quoteId={quoteId}
          orderId={orderId}
        />
      )}
    </div>
  );
};
