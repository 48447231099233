import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Task, TaskCreate } from './types';

export const taskApi = createApi({
  reducerPath: 'taskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/task',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Task'],
  endpoints: (builder) => ({
    getTasks: builder.query<Task[], { orderId?: string, leadId?: string }>({
      query: ({ orderId = "", leadId = "" }) => `?order_id=${orderId}&lead_id=${leadId}`,
      providesTags: ['Task'],
    }),
    getTaskById: builder.query<Task, number>({
      query: (id) => `tasks/${id}/`,
      providesTags: (_result, _err, id) => [{ type: 'Task', id }],
    }),
    createTask: builder.mutation<Task, TaskCreate>({
      query: (task) => ({
        url: '/',
        method: 'POST',
        body: task,
      }),
      invalidatesTags: ['Task'],
    }),
    updateTask: builder.mutation<Task, Partial<Task> & { id: number }>({
      query: ({ id, ...patch }) => ({
        url: `t/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (_result, _err, { id }) => [{ type: 'Task', id }, 'Task'],
    }),
    deleteTask: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Task'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
} = taskApi;
