import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ActionsList } from "./ActionsList";
import { useGetActionsQuery } from "../model/actionsApi";

interface OrderActionsProps {
  orderId: string;
  onClose: any;
}

export const OrderActions = ({ orderId, onClose }: OrderActionsProps) => {
  const { data:list} = useGetActionsQuery({ orderId });
  return (
    <>
      <Dialog open={true} maxWidth="lg">
        <DialogContent>
          Storia del ordine:
          {list && <ActionsList list={list} order />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Chiudere</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
