import { AgGridReact } from 'ag-grid-react'
import { AllCommunityModule, ModuleRegistry, CellClickedEvent, ColDef } from 'ag-grid-community';
import Loader from "shared/ui/loader/loader";
import { useGetCompaniesQuery } from "../model/rtkapi";
import { useMemo, useState, useCallback } from 'react';
import { Pagination } from 'shared/ui/Pagination';
import { CompanyDetails } from './CompanyDetails';
import debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { openWindow } from 'app/windows/windowsSlice';

interface Company {
  id: string;
  name: string;
  comune: string;
  leads: number;
  contacts: number;
  vehicles: number;
  managers: string[];
  models: string[];
}

const PAGE_SIZE = 10;

ModuleRegistry.registerModules([AllCommunityModule]);
export const CompanyList = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useGetCompaniesQuery({
    searchString: debouncedSearch,
    page: currentPage.toString(),
    pageSize: PAGE_SIZE.toString()
  });
  const companies = data?.results;

  const handleCompanyClick = (company: Company) => {
    setSelectedCompany(company);
    searchParams.set("company", company.id);
    setSearchParams(searchParams);
    dispatch(openWindow("companyDetailsWindow"));
  }

  const defaultColDef = useMemo(() => {
    return {
      width: 150,
      cellStyle: { fontWeight: 'normal' },
    };
  }, []);

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
    debouncedSetSearch(value);
  };

  const onCellClicked = (event: CellClickedEvent<Company>) => {
    if (event.column.getColId() === 'name' && event.data) {
      handleCompanyClick(event.data);
    }
  };

  const colDefs: ColDef<Company>[] = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Name', field: 'name', cellStyle: { cursor: 'pointer', color: '#2563eb' } },
    {
      headerName: 'Comune', field: 'comune', sortable: false, filter: true,
      headerComponentParams: {
        enableMenu: true,
        
        onClick: (params: any) => {
          console.log(params);
        }
      }
    },
    { headerName: 'Leads', field: 'leads' },
    {
      headerName: 'Contacts',
      field: 'contacts',
    },
    { headerName: 'Vehicles', field: 'vehicles' },
    { headerName: 'Venditore', field: 'managers' },
    { headerName: 'Modelli', field: 'models' },
  ];

  if (isLoading) return <Loader />;
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full max-w-md mt-4">
        <input
          type="text"
          value={searchString}
          onChange={handleSearchChange}
          placeholder="Cerca aziende..."
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div className="ag-theme-alpine" style={{ height: 500, width: 1200 }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={companies}
          columnDefs={colDefs}
          onCellClicked={onCellClicked}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(data?.count / PAGE_SIZE)}
          onPageChange={setCurrentPage}
        />
      </div>


      <CompanyDetails
        companyId={selectedCompany?.id || ''}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedCompany(null);
        }}
      />
    </div>
  );
};
