import Loader from "shared/ui/loader";
import { useGetQuoteQuery, useUpdateQuoteMutation } from "../model/rtk-api";
import { useGetOrderByIdQuery } from "entities/orders/model/rtk-api";
import { useState, useEffect } from "react";
import { Modal } from "../../../shared/ui/Modal";

interface SailsAnalysisProps {
  isOpen: boolean;
  onClose: () => void;
  quoteId: string;
  orderId: string;
}

interface Option {
  name: string;
  price: number;
  type: 'internal' | 'external';
}

interface Analysis {
  list_price: number;
  discount: number;
  margin: number;
  trans_price: number;
  setup_price: number;
  provision_price: number;
  options: Option[];
  opt_int_price: number;
  opt_ext_price: number;
  vehicle_price: number;
  total_cost: number;
  sale_price: number;
}

interface Field {
  key: keyof Omit<Analysis, 'options'>;
  label: string;
  readOnly?: boolean;
  highlight?: boolean;
}

const fields: Field[] = ([
  { key: 'list_price' as const, label: 'Prezzo listino' },
  { key: 'discount' as const, label: 'Sconto' },
  { key: 'margin' as const, label: 'Margine' },
  { key: 'trans_price' as const, label: 'Trasporto' },
  { key: 'setup_price' as const, label: 'Preparazione' },
  { key: 'provision_price' as const, label: 'Accontanamento' },
  { key: 'opt_int_price' as const, label: 'Allestimento interno', readOnly: true },
  { key: 'opt_ext_price' as const, label: 'Allestimento esterno', readOnly: true },
  { key: 'total_cost' as const, label: 'Costo totale', readOnly: true },
  { key: 'sale_price' as const, label: 'Prezzo di vendita', highlight: true }
] as const).filter(field => !['options'].includes(field.key));

const getOrderValue = (order: any, key: keyof Analysis): number => {
  switch (key) {
    case 'list_price': return Number(order.list_price || 0);
    case 'discount': return Number(order.discount || 0);
    case 'margin': return Number(order.margin || 0);
    case 'trans_price': return Number(order.trans_price || 0);
    case 'setup_price': return Number(order.setup_price || 0);
    case 'provision_price': return Number(order.provision_price || 0);
    case 'opt_int_price': return Number(order.opt_int_price || 0);
    case 'opt_ext_price': return Number(order.opt_ext_price || 0);
    case 'sale_price': return Number(order.sale_price || 0);
    default: return 0;
  }
};

export const SailsAnalysis: React.FC<SailsAnalysisProps> = ({ isOpen, onClose, quoteId, orderId }) => {
  const { data: quote, isLoading: loadingQuote } = useGetQuoteQuery(quoteId);
  const { data: order, isLoading: loadingOrder } = useGetOrderByIdQuery(orderId);
  const [updateQuote] = useUpdateQuoteMutation();

  const initialAnalisis: Analysis = {
    list_price: 0,
    discount: 0,
    margin: 0,
    trans_price: 0,
    setup_price: 0,
    provision_price: 0,
    options: [],
    opt_int_price: 0,
    opt_ext_price: 0,
    vehicle_price: 0,
    total_cost: 0,
    sale_price: 0
  }

  const getInitialData = () => {
    if (quote?.sails_analysis) {
      const parsedData = JSON.parse(quote.sails_analysis);
      return {
        ...parsedData,
        options: parsedData.options || []
      };
    }

    if (order) {
      return {
        list_price: Number(order.list_price) || 0,
        discount: Number(order.discount) || 0,
        margin: Number(order.margin) || 0,
        trans_price: Number(order.trans_price) || 0,
        setup_price: Number(order.setup_price) || 0,
        provision_price: Number(order.provision_price) || 0,
        opt_int_price: Number(order.opt_int_price) || 0,
        opt_ext_price: Number(order.opt_ext_price) || 0,
        opt_ext_margin: Number(order.opt_ext_margin) || 0,
        opt_int_margin: 0,
        options: [],
        total_cost: 0,
        sale_price: 0,
        vehicle_price: 0
      };
    }

    return initialAnalisis;
  };

  const [analisis, setAnalisis] = useState<Analysis>(getInitialData());
  const [isDirty, setIsDirty] = useState(false);
  const [optionName, setOptionName] = useState('');
  const [optionPrice, setOptionPrice] = useState('');
  const [optionType, setOptionType] = useState<'internal' | 'external'>('internal');

  useEffect(() => {
    setAnalisis(getInitialData());
  }, [quote, order]);

  useEffect(() => {
    const total = analisis.list_price - analisis.discount +
      analisis.trans_price + analisis.setup_price +
      analisis.provision_price + analisis.opt_int_price +
      analisis.opt_ext_price;

    setAnalisis(prev => ({
      ...prev,
      total_cost: Number(total.toFixed(2))
    }));
  }, [
    analisis.list_price,
    analisis.discount,
    analisis.trans_price,
    analisis.setup_price,
    analisis.provision_price,
    analisis.opt_int_price,
    analisis.opt_ext_price,
    analisis.sale_price
  ]);

  const handleChange = (key: keyof Analysis, value: string) => {
    setIsDirty(true);
    setAnalisis(prev => ({
      ...prev,
      [key]: Number(parseFloat(value || '0').toFixed(2))
    }));
  };

  const handleSave = () => {
    updateQuote({
      id: quoteId,
      sails_analysis: JSON.stringify(analisis)
    });
    setIsDirty(false);
    onClose();
  };

  const addOption = () => {
    if (optionName && optionPrice) {
      setAnalisis(prev => {
        const price = Number(optionPrice);
        return {
          ...prev,
          options: [...prev.options, {
            name: optionName,
            price: price,
            type: optionType
          }],
          opt_int_price: optionType === 'internal' ? prev.opt_int_price + price : prev.opt_int_price,
          opt_ext_price: optionType === 'external' ? prev.opt_ext_price + price : prev.opt_ext_price
        };
      });
      setOptionName('');
      setOptionPrice('');
      setIsDirty(true);
    }
  };

  const removeOption = (index: number) => {
    setAnalisis(prev => {
      const removedOption = prev.options[index];
      const newOptions = [...prev.options];
      newOptions.splice(index, 1);
      return {
        ...prev,
        options: newOptions,
        opt_int_price: removedOption.type === 'internal' ? prev.opt_int_price - removedOption.price : prev.opt_int_price,
        opt_ext_price: removedOption.type === 'external' ? prev.opt_ext_price - removedOption.price : prev.opt_ext_price
      };
    });
    setIsDirty(true);
  };

  if (loadingQuote || loadingOrder) return <Loader />;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">Analisi vendita</h2>
      <div className="space-y-4">
        <div className="font-bold text-gray-700 mb-2">PREZZO VEICOLO</div>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left py-2">Campo</th>
              <th className="text-right py-2">Valore ordine</th>
              <th className="text-right py-2">Analisi</th>
            </tr>
          </thead>
          <tbody>
            {fields.map(({ key, label, readOnly, highlight }) => (
              <tr key={key} className={`${highlight ? 'bg-yellow-50' : ''}`}>
                <td className="py-2">{label}:</td>
                <td className="text-right px-2">
                  {order ? getOrderValue(order, key).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                </td>
                <td>
                  <input
                    type="number"
                    value={analisis[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    className={`w-full border rounded px-2 py-1 text-right ${highlight ? 'bg-yellow-100' : ''}`}
                    step="1"
                    readOnly={readOnly}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-4">
          <h3 className="font-bold text-gray-700 mb-2">Allestimenti</h3>
          <div className="flex gap-2 mb-2">
            <input
              type="text"
              value={optionName}
              onChange={(e) => setOptionName(e.target.value)}
              placeholder="Nome"
              className="border rounded px-2 py-1 flex-1"
            />
            <input
              type="number"
              value={optionPrice}
              onChange={(e) => setOptionPrice(e.target.value)}
              placeholder="Prezzo"
              className="border rounded px-2 py-1 w-32"
            />
            <select
              value={optionType}
              onChange={(e) => setOptionType(e.target.value as 'internal' | 'external')}
              className="border rounded px-2 py-1"
            >
              <option value="internal">Interno</option>
              <option value="external">Esterno</option>
            </select>
            <button
              onClick={addOption}
              className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Aggiungi
            </button>
          </div>
          
          <div className="space-y-2">
            {analisis.options.map((opt, index) => (
              <div key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded">
                <div>
                  <span>{opt.name}</span>
                  <span className="ml-2 text-sm text-gray-500">
                    ({opt.type === 'internal' ? 'Interno' : 'Esterno'})
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span>{opt.price.toLocaleString('it-IT', { minimumFractionDigits: 2 })} €</span>
                  <button
                    onClick={() => removeOption(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    ✕
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
          >
            Annulla
          </button>
          <button
            onClick={handleSave}
            disabled={!isDirty}
            className={`px-4 py-2 rounded text-white ${isDirty
                ? 'bg-blue-500 hover:bg-blue-600'
                : 'bg-gray-400 cursor-not-allowed'
              }`}
          >
            Salva
          </button>
        </div>
      </div>
    </Modal>
  );
};
