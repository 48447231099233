import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useGetCompanyByIdQuery, useRemoveCompanyMutation } from "../model/rtkapi";
import { LeadCard } from "entities/crm/leads/ui/LeadCard";
import { ContactCard } from "entities/crm/contacts/ui/ContactCard";
import { OrderCard } from "entities/orders/ui/OrderCard";
import { useAppDispatch } from "app/hooks";
import { closeWindow, openWindow } from "app/windows/windowsSlice";
import { useSearchParams } from "react-router-dom";

export const CompanyDetailsWindow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("company");
  const dispatch = useAppDispatch();
  const { data: company, isLoading } = useGetCompanyByIdQuery(companyId || "", { skip: !companyId });
  const [removeCompany] = useRemoveCompanyMutation();

  const openCompanyFormHandler = (id: string) => {
    dispatch(openWindow("companyFormWindow"));
    if (companyId) {
      searchParams.set("company", companyId);
      setSearchParams(searchParams);
    }
  };

  const handleClose = () => {
    dispatch(closeWindow("companyDetailsWindow"));
    searchParams.delete("company");
    setSearchParams(searchParams);
  }

  const handleRemoveCompany = () => {
    removeCompany(companyId);
    dispatch(closeWindow("companyDetailsWindow"));
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      {isLoading ? (
        <DialogContent>
          <div>Caricamento...</div>
        </DialogContent>
      ) : company && (
        <>
          <DialogTitle>Dettagli Azienda</DialogTitle>
          <DialogContent dividers>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col border border-gray-300 rounded-md p-2 bg-white text-sm">
                <div>ID: {company.id}</div>
                <div>Nome: {company.name} </div>
                <div>Comune: {company.comune} </div>
                <div>Indirizzo: {company.address} </div>
                <div>Sito: {company.site} </div>
                <div>Descrizione: {company.desc} </div>
                <div
                  onClick={() => openCompanyFormHandler(company.id)}
                  className="cursor-pointer text-blue-500">
                  Modifica
                </div>
              </div>
              <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
                <span className="absolute top-1 left-3 text-[10px]">CONTATTI</span>
                {company.contacts?.map((contact) => <ContactCard key={contact} contactId={contact} />)}
              </div>
              <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
                <span className="absolute top-1 left-3 text-[10px]">LEAD</span>
                {company.leads?.map((lead) => <div key={lead.id} className="w-auto"><LeadCard lead={lead} /></div>)}
              </div>
              <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
                <span className="absolute top-1 left-3 text-[10px]">VEICOLI</span>
                {company.vehicles?.map((vehicle) => <OrderCard key={vehicle.id} order={vehicle} />)}
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            {company.leads?.length === 0 && company.contacts?.length === 0 && company.vehicles?.length === 0 &&
              <Button variant="contained" color="error" onClick={handleRemoveCompany}>Rimuovi</Button>}
            <Button variant="outlined" onClick={handleClose}>Chiudi</Button>
          </DialogActions>

        </>
      )}
    </Dialog>
  );
};
