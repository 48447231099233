import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow } from "app/windows/windowsSlice";
import { ManPage } from "entities/man/ui/ManPage";
import { useSearchParams } from "react-router-dom";
import { UsedPage } from "entities/used/ui/UsedPage";
export const OrderStockWin = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isOpen = useAppSelector(
    (state) => state.windows.orderStockWindow
  );
  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogContent>
          {searchParams.get("stock") === "man" ? <ManPage /> : <UsedPage />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(closeWindow("orderStockWindow"))}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
