import { Stack } from "@mui/material";
import { useAppDispatch } from "app/hooks";

import { ServiceRow } from "./ServiceRow";
import ClearIcon from "@mui/icons-material/Clear";
import { ServiceCalc } from "../model/calc";

import { useDeleteServiceMutation, useGetServiceByIdQuery, useGetServiceTypeByIdQuery } from "../model/rtk-api";
import { useSearchParams } from "react-router-dom";
import { quoteApi } from "entities/quotes/model/rtk-api";
import { openWindow } from "app/windows/windowsSlice";

interface Props {
  serviceId: string;
  editable: boolean;
}

export const ServiceCard = ({ serviceId, editable }: Props) => {
  const dispatch = useAppDispatch();
  const {data:service} = useGetServiceByIdQuery(serviceId);
  const {data:serviceType} = useGetServiceTypeByIdQuery(service?.service_type || '', {skip: !service});
  const [deleteService] = useDeleteServiceMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteHandler = () => {
    deleteService(serviceId);
    dispatch(quoteApi.util.invalidateTags(['Quote']));
  };
  const openFormHandler = () => {
    if (service?.id) {
      searchParams.set("service", service.id);
      setSearchParams(searchParams);
      dispatch(openWindow("serviceFormWindow"));
    }
  };
  const calc = service ? new ServiceCalc(service) : null;
  return (
    <>
      {serviceType && service && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => (editable ? openFormHandler() : null)}
          >
            <ServiceRow
              list={[
                serviceType.label.toUpperCase(),
                service.desc,
                service.cost.toString(),
                service.margin.toFixed(2).toString() + "%",
                Math.round(calc?.calcProfit() || 0).toString(),
                service.price.toString(),
              ]}
            />
          </div>
          {editable && (
            <div onClick={() => deleteHandler()}>
              <ClearIcon style={{ color: "lightgrey" }} />
            </div>
          )}
        </Stack>
      )}
    </>
  );
};
