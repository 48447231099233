import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow, openWindow } from "app/windows/windowsSlice";
import { useSearchParams } from "react-router-dom";
import { VehicleActions } from "./VehicleActions";
import { useGetVehicleByIdQuery, useUpdateVehicleMutation } from "../api/api";
import { vehicleStatuses } from "../api/constants";

export const VehicleWindow = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get("vehicle");
  const { data: vehicle, isLoading: vehicleLoading } = useGetVehicleByIdQuery(orderId);

  const openOrderHandler = () => {
    searchParams.set("order", vehicle?.id);
    setSearchParams(searchParams);
    dispatch(openWindow("newOrderWindow"));
  };

  const openCompanyHandler = () => {
    searchParams.set("company", vehicle?.company);
    setSearchParams(searchParams);
    dispatch(openWindow("companyDetailsWindow"));
  }

  const [updateVehicle] = useUpdateVehicleMutation()
  const closeHandler = () => {
    dispatch(closeWindow("vehicleWindow"));
    searchParams.delete("vehicle");
    setSearchParams(searchParams);
    updateVehicle({
      id: vehicle?.id,
    })
  };

  const openLeadHandler = () => {
    searchParams.set("lead", vehicle.lead);
    setSearchParams(searchParams);
    dispatch(openWindow("leadDetailsWindow"));
  };

  if (vehicleLoading) return <div>Loading...</div>;

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2}>
          {vehicle && (
            <div>
              STATUS:{" "}
              {
                vehicleStatuses[vehicle.status as keyof typeof vehicleStatuses]
                  .title
              }
            </div>
          )}
          <div className="border p-2 cursor-pointer" onClick={() => openOrderHandler()}>
            <div className="flex items-center gap-2">
              <div>#{vehicle.id}</div>
              <div className="w-10 border-2 border-gray-600 text-gray-600 bg-gray-300 rounded-lg p-1 text-center text-xs font-bold">{vehicle.model}</div>
            </div>
            <div>{vehicle.code || "AUFTRAG OR CODICE NON ASSEGNATO"}</div>
          </div>
          {/* {order && <OrderCard order={order} />} */}
          {vehicle?.reserved && (
            <Stack spacing={2}>
              <div
                style={{ cursor: "pointer", color: "darkblue" }}
                onClick={() => openLeadHandler()}
              >
                LEAD #{vehicle?.lead}
              </div>

              {/* <div
                style={{ cursor: "pointer", color: "darkblue" }}
                onClick={() => openQuoteHandler()}
              >
                PREVENTIVO #{order.reserved}
              </div> */}
            </Stack>
          )}
          {vehicle.company_name && (
            <div className="cursor-pointer text-blue-700 hover:underline" onClick={() => openCompanyHandler()}>
              {vehicle.company_name}
            </div>
          )}
          <VehicleActions vehicle_id={vehicle?.id} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};
