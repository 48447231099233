import { useState, useEffect } from "react";
import { useGetPublicQuery, useUpdatePublicMutation } from "../model/api";
import { PhotoManager } from "./PhotoManager";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { getRoles } from "entities/viewer/model";
import { AdConfig } from "./AdConfig";
import { useAppSelector } from "app/hooks";
import { Publics } from "../model/types";
import axios from "axios";
import { Order } from "types/order";

const inputClassName = "w-full px-3 py-2 text-sm border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number | undefined;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ type = "text", value, placeholder, onChange, className = inputClassName, ...props }) => (
  <input
    type={type}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    className={className}
    {...props}
  />
);

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = ({ value, placeholder, onChange, rows = 4, className = inputClassName, ...props }) => (
  <textarea
    value={value}
    placeholder={placeholder}
    rows={rows}
    onChange={onChange}
    className={className}
    {...props}
  />
);

interface Props {
  publicId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  //public_: Publics;
}

export const PublicForm = ({ publicId, open, setOpen }: Props) => {
  const { data: publicData } = useGetPublicQuery(publicId);
  const [search, setSearch] = useState<string>("");
  const [searchedOrders, setSearchedOrders] = useState<Order[]>();
  const [searchError, setSearchError] = useState<string>("");

  const [formData, setFormData] = useState<Publics>(() => {
    if (publicData) {
      return publicData;
    }
    return {
      id: publicId,
      title: "",
      subtitle: "",
      desc: "",
      slug: "",
      price: "",
      type: "",
      permanent: false,
      show_price: false,
      order: "",
      abbr: "",
      km: null,
      year: null,
      engine: null,
      trans: "",
      promo: false,
      promo_price: null,
      list_price: null,
      show_list_price: false,
    };
  });

  useEffect(() => {
    if (publicData) {
      setFormData(publicData);
    }
  }, [publicData]);

  const [updatePublic, { isLoading }] = useUpdatePublicMutation();
  const roles = useAppSelector(getRoles);

  const handleTextChange =
    (field: keyof Publics) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({
          ...prev,
          [field]: event.target.value,
        }));
      };

  const handleSwitchChange =
    (field: keyof Publics) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.checked,
      }));
    };

  const handleSelectChange =
    (field: keyof Publics) => (event: SelectChangeEvent) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const savePublicHandler = async () => {
    try {
      await updatePublic(formData).unwrap();
      setOpen(false);
    } catch (error) {
      console.error("Failed to save public:", error);
      alert("Failed to save public");
    }
  };

  const searchOrder = async (str: string) => {
    await axios
      .get(`/api/order/search/?search=${str}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        setSearchedOrders(data);
      })
      .catch((er) => {
        console.log(er.message);
        setSearchError("Error");
      });
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-2 w-1/2">
            {roles?.includes("public_manager") && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.permanent}
                      onChange={handleSwitchChange("permanent")}
                      inputProps={{ "aria-label": "Permanente" }}
                    />
                  }
                  label="Permanente"
                />
                {formData.permanent && (
                  <>
                    <div>
                      <Input
                        value={formData.slug}
                        placeholder="slug"
                        onChange={handleTextChange("slug")}
                      />
                    </div>

                    <div>
                      <label htmlFor="search" className="text-xs text-gray-500">
                        Ref ordine
                      </label>
                      <Input
                        value={search}
                        placeholder="Cerca ordine"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button onClick={() => searchOrder(search + " in stock")}>
                        Cerca
                      </Button>
                    </div>

                    {searchedOrders && searchedOrders?.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel id="select-label">Ordine</InputLabel>
                        <Select
                          style={{ fontSize: "12px" }}
                          size="small"
                          labelId="select-label"
                          value={formData.order}
                          label="Ordine"
                          onChange={handleSelectChange("order")}
                        >
                          {searchedOrders?.map((item) => (
                            <MenuItem
                              key={item.id}
                              style={{ fontSize: "12px" }}
                              value={item.id}
                            >
                              ref:{item.id} {item.model_desc || ""} {item.code || ""} {item.abbreviation || ""}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </>
            )}

            <Input
              value={formData.title}
              placeholder="Titolo"
              onChange={handleTextChange("title")}
            />

            <div>{formData.abbr}</div>

            <Input
              value={formData.subtitle}
              placeholder="Sottotitolo"
              onChange={handleTextChange("subtitle")}
            />

            {formData.config && <AdConfig config={formData.config} />}

            <Input
              type="number"
              value={formData.km || ""}
              placeholder="Km"
              onChange={handleTextChange("km")}
            />

            <Input
              type="number"
              value={formData.year || ""}
              placeholder="Anno"
              onChange={handleTextChange("year")}
            />

            <Input
              type="number"
              value={formData.engine || ""}
              placeholder="Cavalli"
              onChange={handleTextChange("engine")}
            />

            <Input
              type="text"
              value={formData.trans}
              placeholder="Cambio"
              onChange={handleTextChange("trans")}
            />

            <TextArea
              value={formData.desc}
              placeholder="Descrizione"
              onChange={handleTextChange("desc")}
            />

            <FormControl fullWidth size="small">
              <InputLabel id="type-select-label">Tipo veicolo</InputLabel>
              <Select
                labelId="type-select-label"
                value={formData.type}
                label="Tipo veicolo"
                onChange={handleSelectChange("type")}
                size="small"
              >
                <MenuItem value="camion">Camion</MenuItem>
                <MenuItem value="furgone">Furgone</MenuItem>
                <MenuItem value="semirimorchio">Semirimorchio</MenuItem>
              </Select>
            </FormControl>

            <Input
              value={formData.price}
              placeholder="Prezzo"
              onChange={handleTextChange("price")}
            />

            <Input
              value={formData.promo_price || ""}
              placeholder="Prezzo promo"
              onChange={handleTextChange("promo_price")}
            />

            <Input
              value={formData.list_price || ""}
              placeholder="Prezzo listino"
              onChange={handleTextChange("list_price")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(formData.show_list_price)}
                  onChange={handleSwitchChange("show_list_price")}
                  inputProps={{ "aria-label": "Mostra prezzo di listino" }}
                />
              }
              label="Mostra prezzo di listino"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(formData.show_price)}
                  onChange={handleSwitchChange("show_price")}
                  inputProps={{ "aria-label": "Mostra prezzo" }}
                />
              }
              label="Mostra prezzo"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(formData.promo)}
                  onChange={handleSwitchChange("promo")}
                  inputProps={{ "aria-label": "Promo" }}
                />
              }
              label="Promo"
            />
            
          </div>
          <div className="w-1/2">
            <PhotoManager publicId={formData.id} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={isLoading}>
          CHIUDI
        </Button>
        <Button onClick={savePublicHandler} disabled={isLoading}>
          {isLoading ? "Salvando..." : "Salva"}
        </Button>
      </DialogActions>
    </Dialog>
  );


};

