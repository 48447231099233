export const manConfig = {
  tgx: {
    types: ["TRATTORE", "CARRO"],
    cabineTypes: ["GX", "GM", "GN"],
    engines: ["400", "440", "480", "520", "540", "560", "580", "640"],
    carloads: ["18", "26", "35"]
  },
  tgs: {
    types: ["TRATTORE", "CARRO", "MEZZO D'OPERA"],
    cabineTypes: ["TM", "TN", "NN"],
    engines: ["330", "360", "400", "440", "480", "520", "540", "580", "640"],
    carloads: {
      trattore: ["18", "33"],
      carro: ["18", "26", "35"],
      mezzo: ["33", "41"]
    }
  },
  tgmTgl: {
    cabineTypes: ["TM", "TN", "CC"],
    engines: {
      tgl: ["160", "190", "220", "250"],
      tgm: ["250", "290", "320"]
    }
  },
  tge: {
    types: ["FURGONE", "TELAIO CAB.", "TELAIO DOPP.CAB.", "9 POSTI"],
    topTypes: ["BASSO", "ALTO", "ALTISSIMO"],
    traction: ["ANT.", "4 X 4", "POST."],
    wheelbase: ["LUNGO", "CORTO"],
    engines: ["100", "140", "160", "180"],
    setups: ["CASSONE FISSO", "CASSONE RIB.", "CELLA FRIGO"]
  },
  common: {
    suspension: ["BL", "LL", "BB"],
    transmission: ["MANUALE", "AUTO"],
    colors: ["ROSSO", "GRIGIO", "BIANCO", "VERDE", "GIALLO", "NERO", "BLU", "MARRONE", "ALTRO"]
  }
};