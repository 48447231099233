interface ContentTypeRule {
  type: string;
  detect: (text: string) => boolean;
  extract: (text: string) => Record<string, any>;
}

const contentRules: ContentTypeRule[] = [
  {
    type: 'order',
    detect: (text: string) => {
      const orderPatterns = [
        /ordine\s+cliente/i,
        /AUFTRAG/i,
        /Totale\s+Lordo\s+Listino/i
      ];
      return orderPatterns.some(pattern => pattern.test(text));
    },
    extract: (text: string) => {
      const orderData: Record<string, any> = {
        rif: "",
        auftrag: [],
        lordoListino: 0,
        trasporto: 0,
        veicoloFattura: 0,
        allestimento: 0,
      };

      // Estrai Auftrag (possono essere multipli)
      const auftragMatch = text.match(/AUFTRAG:\s*([\d/\s-]+)/i);
      if (auftragMatch) {
        const auftragNumbers = auftragMatch[1]
          .trim()
          .split(/[\s-]+/)
          .filter(a => /^\d{4}\/\d{5}$/.test(a));
        if (auftragNumbers.length > 0) {
          orderData.auftrag = auftragNumbers;
        }
      }

      const rifMatch = text.match(/RIF\.\s*PRATICA:\s*([\w-]+)/i);
      if (rifMatch) {
        orderData.rif = rifMatch[1];
      }

      // Estrai Totale Lordo Listino
      const lordoMatch = text.match(/Totale\s+Lordo\s+Listino\s*:\s*€?\s*([\d.,]+)(?:\s+\d+(?:[,.]\d+)?\s*%)?/i);
      if (lordoMatch) {
        orderData.lordoListino = parseFloat(lordoMatch[1].replace(/\./g, '').replace(',', '.'));
      }

      // Estrai Trasporto
      const trasportoMatch = text.match(/Trasporto\s*:\s*\+?\s*€?\s*([\d.,]+)/i);
      if (trasportoMatch) {
        orderData.trasporto = parseFloat(trasportoMatch[1].replace(/\./g, '').replace(',', '.'));
      }

      // Estrai Totale veicolo fattura
      const totaleVeicoloMatch = text.match(/TOTALE\s+VEICOLO\s+FATTURA\s*:\s*€?\s*([\d.,]+)/i);
      if (totaleVeicoloMatch) {
        orderData.veicoloFattura = parseFloat(totaleVeicoloMatch[1].replace(/\./g, '').replace(',', '.'));
      }

      // Estrai Totale allestimento
      const allestimentoMatch = text.match(/Totale\s+allestimento\s*:\s*€?\s*([\d.,]+)/i);
      if (allestimentoMatch) {
        orderData.allestimento = parseFloat(allestimentoMatch[1].replace(/\./g, '').replace(',', '.'));
      }

      return orderData;
    }
  },
  {
    type: 'invoice',
    detect: (text: string) => {
      const invoicePatterns = [
        /fattura\s+n[.,]?\s*\d+/i,
        /p\.iva|codice\s+fiscale/i,
        /importo\s+totale|totale\s+fattura/i
      ];
      return invoicePatterns.some(pattern => pattern.test(text));
    },
    extract: (text: string) => {
      const invoiceData: Record<string, any> = {
        items: [],
        totals: {},
        vendor: {},
        customer: {}
      };

      // Estrai numero fattura
      const invoiceMatch = text.match(/fattura\s+n[.,]?\s*(\d+)/i);
      if (invoiceMatch) invoiceData.invoiceNumber = invoiceMatch[1];

      // Estrai P.IVA
      const vatMatch = text.match(/p\.iva\s*[:.]?\s*(\d+)/i);
      if (vatMatch) invoiceData.customer.vat = vatMatch[1];

      // Estrai importo totale
      const totalMatch = text.match(/totale\s+(?:fattura|importo)\s*[:.]?\s*€?\s*(\d+(?:[,.]\d{2})?)/i);
      if (totalMatch) invoiceData.totals.total = parseFloat(totalMatch[1].replace(',', '.'));

      // Estrai articoli
      const itemsRegex = /(\d+)\s*x\s*([^\n]+?)(?:\s+€?\s*(\d+(?:[,.]\d{2})?))?\s*(?:\n|$)/gm;
      let match;
      while ((match = itemsRegex.exec(text)) !== null) {
        invoiceData.items.push({
          quantity: parseInt(match[1]),
          description: match[2].trim(),
          price: match[3] ? parseFloat(match[3].replace(',', '.')) : null
        });
      }

      return invoiceData;
    }
  }
];

export interface DetectedContent {
  type: string;
  data: Record<string, any>;
}

export const detectContentType = (data: any): DetectedContent => {
  // Se i dati sono in formato JSON
  if (typeof data === 'object' && data !== null) {
    if (data.invoiceNumber && data.totalAmount) {
      return { type: 'invoice', data };
    } else if (data.orderId && data.items) {
      return { type: 'order', data };
    } else if (data.configName && data.settings) {
      return { type: 'configuration', data };
    }
  }

  // Se i dati sono in formato testo
  if (typeof data === 'string') {
    for (const rule of contentRules) {
      if (rule.detect(data)) {
        return {
          type: rule.type,
          data: rule.extract(data)
        };
      }
    }
  }

  return { type: 'unknown', data: { text: data } };
};