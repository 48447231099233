import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Stack
} from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { closeWindow } from "app/windows/windowsSlice";
import { useState } from "react";
import { useCreateTaskMutation } from "../model/api";
import { useGetManagersQuery } from "entities/managers/model/rtk-api";
import { Manager } from "entities/managers/model/types";
import { useSearchParams } from "react-router-dom";
export const TaskFormWindow = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [createTask] = useCreateTaskMutation();
  const {data:managers} = useGetManagersQuery();
  const [formData, setFormData] = useState({
    description: "",
    status: "new",
    order: searchParams.get("order") || "",
    lead: searchParams.get("lead") || "",
    assigned_to_id: "" as string | number
  });

  console.log(formData)

  const handleSubmit = async () => {
    try {
      await createTask({
        ...formData,
        assigned_to_id: formData.assigned_to_id ? Number(formData.assigned_to_id) : undefined,
        order: formData.order ? Number(formData.order) : undefined,
        lead: formData.lead ? Number(formData.lead) : undefined
      }).unwrap();
      dispatch(closeWindow("taskFormWindow"));
    } catch (error) {
      console.error("Errore durante la creazione del task:", error);
    }
  };

  const handleClose = () => {
    dispatch(closeWindow("taskFormWindow"));
  };

  return (
    <Dialog 
      open={true} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Nuovo Task</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          
          <TextField
            label="Descrizione"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            multiline
            rows={3}
            fullWidth
            required
          />

          <FormControl fullWidth>
            <InputLabel>Stato</InputLabel>
            <Select
              value={formData.status}
              label="Stato"
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
            >
              <MenuItem value="new">Nuovo</MenuItem>
              <MenuItem value="in_progress">In Corso</MenuItem>
              <MenuItem value="completed">Completato</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Assegnato a</InputLabel>
            <Select
              value={formData.assigned_to_id}
              label="Assegnato a"
              onChange={(e) => setFormData({ ...formData, assigned_to_id: e.target.value })}
            >
              <MenuItem value="">Nessuno</MenuItem>
              {managers && Object.values(managers)?.map((manager:Manager) => (
                <MenuItem key={manager.id} value={manager.user}>
                  {manager.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annulla</Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={!formData.description}
        >
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
}