import { createSlice } from "@reduxjs/toolkit";

interface WindowsState {
  createLeadWindow: boolean;
  leadFilesWindow: boolean;
  leadDetailsWindow: boolean;
  quoteFormWindow: boolean;
  quoteDelWindow: boolean;
  taskFormWindow: boolean;
  newOrderWindow: boolean;
  orderStockWindow: boolean;
  addCompanyToLead: boolean;
  contactFormWindow: boolean;
  companyFormWindow: boolean;
  serviceFormWindow: boolean;
  serviceCreateWindow: boolean;
  vehicleWindow: boolean;
  companyDetailsWindow: boolean;
}

const initialState: WindowsState = {
  createLeadWindow: false,
  leadFilesWindow: false,
  leadDetailsWindow: false,
  quoteFormWindow: false,
  quoteDelWindow: false,
  taskFormWindow: false,
  newOrderWindow: false,
  orderStockWindow: false,
  addCompanyToLead: false,
  contactFormWindow: false,
  companyFormWindow: false,
  serviceFormWindow: false,
  serviceCreateWindow: false,
  vehicleWindow: false,
  companyDetailsWindow: false,
};

export const windowsSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    openWindow: (state, { payload }) => {
      state[payload as keyof WindowsState] = true;
    },
    closeWindow: (state, { payload }) => {
      state[payload as keyof WindowsState] = false;
    },
  },
});

export const {
  openWindow,
  closeWindow,
} = windowsSlice.actions;

export default windowsSlice.reducer;
