import { useAppDispatch } from "app/hooks";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { openWindow } from "app/windows/windowsSlice";
import { ManOrder } from "../model/types";
import { ProdStatus } from "./ProdStatus";
import { Plate } from "shared/ui/Plate";

interface OrderProps {
  order: ManOrder;
}

export const ManOrderCard = ({ order }: OrderProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const openOrderHandler = () => {
    searchParams.set("order", order.id.toString());
    setSearchParams(searchParams);
    dispatch(openWindow("newOrderWindow"));
  };

  // const keyDownHandler = (event: KeyboardEvent) => {
  //   if (event.key === "Enter") dispatch(setCurrent(order.id));
  // };

  return (
    <div
      className="cursor-pointer w-full border border-gray-300 rounded-lg flex flex-col"
      onClick={() => openOrderHandler()}
    //onKeyDown={keyDownHandler}
    >

      <div className="flex items-center justify-between h-8 bg-gray-800 text-left text-white p-2 rounded-t-lg text-sm">
        <div>
          {order.code ? (
            <>
              {order.code.toString().split("/")[0]} /{" "}
              {order.code.toString().split("/")[1]}
            </>
          ) : (
            <>---- / -----</>
          )}
        </div>
        <span className="text-lightblue ml-5">{` ref:${order.id}`}</span>
        {/* {order.type === "SEMIRIMORCHIO" && <div>SEMIRIM.</div>} */}
        {/* {order.type === "USATO" && <div>USATO #{order.id}</div>} */}
        {/* {new Date(order.updated).toISOString().substring(0, 10) ===
          new Date().toISOString().substring(0, 10) && (
            <span>
              <NewReleasesTwoToneIcon className="h-4" />
            </span>
          )} */}
        {/* {!order.list_price && (
          <span>
            <ErrorOutlineOutlinedIcon className="h-4 text-orange-500" />
          </span>
        )} */}
      </div>

      <div className="min-h-[140px] text-xs p-2">
        {/* {order.model_desc && order.model_desc.length > 1 && (
            <div className="m-2 font-normal">{order.model_desc.substring(0, 25)}</div>
          )} */}
        <div className="flex mt-1 items-center gap-3">
          {order.model && (
            <div className="border-2 border-gray-600 text-gray-600 bg-gray-300 rounded-lg p-1 text-center text-xs font-bold">
              {order.model}
            </div>
          )}
          <div className="w-3/4">
            {order.name.toString().substring(0, 24)}
          </div>
        </div>

        <div>
          <div className="flex mt-1 space-x-2 items-center">
            {order.plate ? <Plate plate={order.plate} /> : <ProdStatus manOrder={order} />}
            <div className="text-xs">
              {order.abbreviation && <span>{order.abbreviation.substring(0, 10)}</span>}
            </div>

          </div>
          {order.created && (
            <div>
              Creato:{" "}
              {new Date(order.created).toLocaleDateString("it-IT")}
            </div>
          )}
          {order.dt_product && !order.dt_supply && (
            <div>
              Data produzione:{" "}
              {new Date(order.dt_product).toLocaleDateString("it-IT")}
            </div>
          )}
          {order.dt_planned && (
            <div>
              Data consegna:{" "}
              {new Date(order.dt_planned).toLocaleDateString("it-IT")}
            </div>
          )}
          {dayjs(order.dt_end_edit).isAfter(dayjs()) && (
            <div>
              Stop modifiche:{" "}
              {new Date(order.dt_end_edit).toLocaleDateString("it-IT")}
            </div>
          )}

          <div >
            {order.dt_supply && (
              <>
                Fornito:{" "}
                {new Date(order.dt_supply).toLocaleDateString("it-IT")}
              </>
            )}
          </div>

          {/* <div >
            {order.chassis && <>Telaio: {order.chassis}</>}
          </div> */}
        </div>
      </div>
      <div className="border-t" />
      <div>
        <div className="flex items-center justify-around  min-h-[12px]">
          <div
            role="status"
            className={`text-center text-sm uppercase p-1`}
          >
            {order.status}{" "}
            <span className="text-gray-500 text-xs">
              {order.dt_sale && dayjs(order.dt_sale).format(`DD/MM/YY`)}
            </span>
          </div>
          {/* <div className="flex space-x-1">
            {order.status === "in stock" && order.quotes.length !== 0 && (
                <div className="border-2 border-gray-600 text-gray-600 bg-gray-200 rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold">
                  {order.quotes.length}
                </div>
              )}
          </div> */}
        </div>
      </div>
    </div>

  );
};
