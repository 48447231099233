import { useAppDispatch } from "app/hooks";
import { Used } from "../api/types";
import { useSearchParams } from "react-router-dom";
import { openWindow } from "app/windows/windowsSlice";
import FurgonSVG from "../../../app/svg/furgon";
import { TruckSVG } from "../../../app/svg/truck";
import { TrailerSVG } from "../../../app/svg/trailer";
import { TrattoreSVG } from "../../../app/svg/trattore";
import { Plate } from "shared/ui/Plate";
import { TopUsedSVG } from "app/svg/topused";

interface UsedCardProps {
  item: Used;
}

export const UsedCard: React.FC<UsedCardProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const openOrderHandler = () => {
    searchParams.set("order", item.order.toString());
    setSearchParams(searchParams);
    dispatch(openWindow("newOrderWindow"));
  };

  return (
    <div
      onClick={() => openOrderHandler()}
      className="relative bg-white h-40 border cursor-pointer hover:bg-gray-100 border-gray-300 rounded-md shadow-sm flex flex-col"
    >
      <div className="bg-gray-600 text-right w-full border-gray-600 rounded-t-md  flex items-center justify-between">
        {item.topused ? (
          <div className="flex">
            <div className="bg-gray-300 p-[3px] h-6 rounded-tl-sm">
              <TopUsedSVG />
            </div>
            <div className="w-6 h-6 bg-gradient-to-r from-gray-300 to-transparent">
              {""}
            </div>
          </div>
        ) : (
          <div>{""}</div>
        )}
        <div className="p-1 text-xs  text-white">ref: #{item.order}</div>
      </div>

      <div className="flex items-center text-left mt-2 ml-2 gap-2">
        <div className="p-[3px] bg-gray-200 border-2 rounded-md text-xs font-bold border-gray-500">
          UST
        </div>
        <div className="text-gray-500 text-xs font-bold pt-1 truncate ...">
          {item.model || "No Model"}
        </div>
      </div>
      <div className="flex items-center gap-1">
        <div className="text-xs uppercase fill-gray-500 p-2">
          {item.category === "35qt" ? (
            <FurgonSVG />
          ) : item.category === "motrice" ? (
            <TruckSVG />
          ) : item.category === "trattore" ? (
            <TrattoreSVG />
          ) : item.category === "semirimorchio" ? (
            <TrailerSVG />
          ) : (
            item.category
          )}
        </div>

        <div className={`flex gap-1 text-sm uppercase text-gray-600 `}>
          {item.fis_status}{" "}
          <div
            className={`text-sm font-bold uppercase text-gray-600 ${
              item.safe_arrival ? "text-green-800" : "text-red-800"
            }`}
          >
            {item.dt_arrive &&
              item.fis_status === "in arrivo" &&
              new Date(item.dt_arrive).toLocaleDateString("IT-it")}
          </div>
        </div>
      </div>
      <div className="ml-2">{item.plate && <Plate plate={item.plate} />}</div>
      {/* {item.fis_status === "in arrivo" && (
        <div className="text-sm uppercase text-gray-600">
          {item.safe_arrival ? "SICURO" : "NON SICURO"}
        </div>
      )} */}

      <div className="absolute bottom-0 left-0 w-full border-t-2 text-gray-800 text-sm p-1 text-center uppercase">
        {" "}
        {item.com_status}
      </div>
    </div>
  );
};
