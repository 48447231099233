import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
} from "@mui/material";
import { useAppSelector } from "app/hooks";
import { OrderCalc } from "entities/orders/model/calc";
import { getRoles } from "entities/viewer/model";
import { useState } from "react";
import { Order } from "types/order";
import { OrderInlineEdit } from "../OrderInlineEdit";

interface CalcCardProps {
  order: Order;
}

interface CalcRowProps {
  title: string;
  value: number | null;
  textColor?: string;
  bold?: boolean;
}

const CalcRow = ({
  title,
  value,
  textColor = "primary",
  bold = false,
}: CalcRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginY={1}>
      <div
        style={{
          fontSize: "13px",
          color: textColor,
          fontWeight: bold ? "bold" : "lighter",
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: "13px",
          color: textColor,
          fontWeight: bold ? "bold" : "normal",
        }}
      >
        {value?.toLocaleString("it-IT")} €
      </div>
    </Stack>
  );
};

export const CalcCard = ({ order }: CalcCardProps) => {
  const orderCalc = new OrderCalc(order);
  const [edit, setEdit] = useState(false);
  const roles = useAppSelector(getRoles);

  // const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(setManualCalc({ id: order.id, value: event.target.checked }));
  // };

  const saveChanges = () => {
    setEdit(false);
  };

  return (
    <>
      <Paper sx={{ padding: "10px" }}>
        <CalcRow title="PREZZO NETTO" value={orderCalc.calcNetto()} />
        <CalcRow title="PREZZO ALLESTIMENTI" value={orderCalc.calcOptPrice()} />
        <CalcRow title="PREZZO MINIMO" value={orderCalc.calcMinPrice()} />
        <CalcRow title="PREZZO VENDITA" value={orderCalc.sale_price} bold />
        {!order.manual_calc && (
          <>
            <CalcRow title="PROFITTO" value={orderCalc.calcProfit()} />
            <CalcRow
              title="VENDITORE BASE"
              value={orderCalc.calcSellerBase()}
            />
            <CalcRow
              title="VENDITORE ALLEST. EST."
              value={orderCalc.calcSellerOptExt()}
            />
            <CalcRow
              title="VENDITORE 50% PROFIT"
              value={orderCalc.calcSellerProfit()}
            />
          </>
        )}
        <CalcRow title="TOTALE VENDITORE" value={orderCalc.calcSeller()} />
        {roles?.includes("crm_manager") && (
          <CalcRow
            title="TOTALE AZIENDA"
            value={orderCalc.calcFirm()}
            textColor={orderCalc.calcFirm() > 0 ? "darkgreen" : "darkred"}
          />
        )}
        {order.status !== "fatturato" && roles?.includes("crm_manager") && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              sx={{ alignContent: "right" }}
              variant="outlined"
              size="small"
              onClick={() => setEdit(true)}
            >
              MODIFICA
            </Button>
          </div>
        )}
      </Paper>

      <Dialog open={edit}>
        <DialogContent>
          <>
            {/* Calcolo manuale:{" "}
            <Switch
              checked={order.manual_calc}
              onChange={(e) => switchHandleChange(e)}
            /> */}
            {order.manual_calc && (
              <div>
                BONUS VENDITORE:{" "}
                <OrderInlineEdit order={order} field="seller_bonus" />
              </div>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={() => saveChanges()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
