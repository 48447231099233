import {
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DeliveryBoard } from "./DeliveryBoard";
import { LeadBoard } from "./LeadBoard";
import { Manager } from "entities/managers/model/types";
import { useGetManagersQuery } from "entities/managers/model/rtk-api";
import { openWindow } from "app/windows/windowsSlice";

export const CrmBoard = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.viewer.user);
  const [searchString, setSearchString] = useState("");
  const [leadsOwner, setLeadsOwner] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("lead")) {
      dispatch(openWindow("leadDetailsWindow"));
    }
  }, [searchParams, dispatch]);

  const stages = ["vendita", "consegna", "supporto"];
  //const [stage, setStage] = useState(stages[0]);
  const boardType = searchParams.get("board") || stages[0];

  useEffect(() => {
    if (!boardType) searchParams.set("board", stages[0]);
    setSearchParams(searchParams);
  }, []);

  const nextStageHandler = () => {
    let nextIndex = stages.indexOf(boardType) + 1;
    if (nextIndex === 3) nextIndex = 0;
    searchParams.set("board", stages[nextIndex]);
    setSearchParams(searchParams);
  };

  const prevStageHandler = () => {
    let nextIndex = stages.indexOf(boardType) - 1;
    if (nextIndex === -1) nextIndex = 2;
    searchParams.set("board", stages[nextIndex]);
    setSearchParams(searchParams);
  };

  const { data: managers } = useGetManagersQuery();
  const sellerList = managers ? Object.values(managers).filter((item) => item.seller) : [];

  return (
    <div>
      {boardType === "vendita" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          {user?.roles.includes("crm_manager") && sellerList && (
            <Stack width="auto" direction="row" spacing={2}>
              <Select
                size="small"
                sx={{ width: "200px" }}
                value={leadsOwner}
                onChange={(e) => setLeadsOwner(e.target.value)}
              >
                {sellerList.map((item: Manager) => (
                  <MenuItem key={item.id} value={item.user}>
                    {item.name ? item.name : "LIBERI"}
                  </MenuItem>
                ))}
                <MenuItem value="">LIBERI</MenuItem>
              </Select>
            </Stack>
          )}
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              value={searchString}
              placeholder="Azienda o nome"
              inputProps={{ "aria-label": "azienda o nome" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(event.target.value);
              }}
            />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={() => setSearchString("")}
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        </div>)}
      <Stack
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          color: "white",
          backgroundColor: "primary.light",
          // boardType === "vendita"
          //   ? "primary.light"
          //   : boardType === "consegna"
          //   ? "#f2ce72"
          //   : "#ccccff",
        }}
      >
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => prevStageHandler()}
        />
        <div>{boardType?.toUpperCase()}</div>
        <ArrowForwardIcon
          sx={{ cursor: "pointer" }}
          onClick={() => nextStageHandler()}
        />
      </Stack>
      {boardType === "consegna" && (
        <DeliveryBoard leadsOwner={leadsOwner} />
      )}
      {boardType === "vendita" && (
        <LeadBoard leadsOwner={leadsOwner} searchString={searchString} />
      )}
    </div>
  );
};
