import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Action, SendActionType } from './types';

export const actionsApi = createApi({
  reducerPath: 'actionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Actions'],
  endpoints: (builder) => ({
    getActions: builder.query<Action[], { orderId?: string; leadId?: string; date?: string; prev?: boolean; next?: boolean }>({
      query: ({ orderId, leadId, date, prev = false, next = false }) => ({
        url: 'actions',
        params: {
          order: orderId,
          lead: leadId,
          date,
          prev,
          next,
        },
      }),
      providesTags: ['Actions'],
    }),
    sendAction: builder.mutation<Action, SendActionType>({
      query: (body) => ({
        url: 'send-action/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Actions'],
    }),
  }),
});

export const {
  useGetActionsQuery,
  useSendActionMutation,
} = actionsApi; 