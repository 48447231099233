import { Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import "dayjs/locale/it";
import { useGetActionsQuery } from "entities/actions/model/actionsApi";
import { ActionsList } from "entities/actions/ui/ActionsList";

export const ActionsPage = () => {
  const [dt, setDt] = useState<Dayjs | null>(dayjs());
  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(false);
  
  const { data: list = [] } = useGetActionsQuery({
    date: dt?.format("YYYY-MM-DD"),
    prev: isPrev,
    next: isNext
  });

  useEffect(() => {
    if (list.length > 0) setDt(dayjs(list[0].timestamp));
    setIsPrev(false);
    setIsNext(false);
  }, [list]);

  const handlePrev = () => {
    setIsPrev(true);
  };

  const handleNext = () => {
    setIsNext(true);
  };

  const handleDateChange = (value: Dayjs | null) => {
    setDt(value);
  };

  return (
    <div>
      <Stack
        sx={{ width: "280px" }}
        marginY={3}
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={handlePrev}
        >
          {"<<"}
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
          <DatePicker
            value={dt}
            onChange={handleDateChange}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={handleNext}
        >
          {">>"}
        </div>
      </Stack>
      <ActionsList list={list} rows={30} />
    </div>
  );
};
