import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Loader from "shared/ui/loader/loader";
import { useEffect, useRef, useState } from "react";
import { DocsTable } from "./DocsTable";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { DocsList } from "./DocsList";
import { useGetFilesQuery, useUploadFileMutation } from "../model/rtk-api";

interface FileListProps {
  orderId: string;
}

export const FileList = ({ orderId }: FileListProps) => {

  const { data: files, isLoading } = useGetFilesQuery(orderId);
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
  const drop = useRef<HTMLDivElement>(null);
  const [pdfView, setPdfView] = useState(true);
  const [dragging, setDragging] = useState(false);
  

  useEffect(() => {
    // Aggiungiamo un piccolo ritardo per assicurarci che il DOM sia pronto
    const timer = setTimeout(() => {
      if (drop.current) {        
        drop.current.addEventListener("dragover", handleDragOver);
        drop.current.addEventListener("drop", handleDrop);
        drop.current.addEventListener("dragenter", handleDragEnter);
        drop.current.addEventListener("dragleave", handleDragLeave);
      }
    }, 100);
    
    return () => {
      clearTimeout(timer);
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
        drop.current.removeEventListener("dragenter", handleDragEnter);
        drop.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []); 

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    uploadFile({ files, orderId });
    setDragging(false);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const changeTableView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPdfView(event.target.checked);
  };

  if (isLoading || isUploading) return <Loader />;
  return (
    <div
      ref={drop}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <FormControlLabel
        control={<Switch checked={pdfView} onChange={changeTableView} />}
        label="PDF"
      />

      <Stack
        style={{
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
          border: dragging ? "3px dotted grey" : "1px dotted grey",
          minHeight: "50px",
          padding: 10,
        }}
      >
        <Typography color="grey">
          {/* CTRL + V PER INCOLLARE OPPURE TRASCINA FILE CON MOUSE */}
        </Typography>
        <>
          {files && files.length > 0 && <>
            {pdfView ? <DocsList files={files} /> : <DocsTable files={files} />}
          </>}
        </>

      </Stack>
    </div>
  );
};
