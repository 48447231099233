import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Company } from "./types";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/crm/company/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Companies", "Company"],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: (params: { searchString?: string, page?: string, pageSize?: string } = {}) => 
        `?name=${params.searchString || ''}&page=${params.page || '1'}&page_size=${params.pageSize || '10'} `,
      providesTags: ["Companies"],
    }),
    getCompanyById: builder.query<Company, string>({
      query: (id) => `${id}`,
      providesTags: ["Company"],
    }),
    addCompany: builder.mutation({
      query: (company) => ({
        url: "",
        method: "POST",
        body: company,
      }),
      invalidatesTags: ["Companies"],
    }),
    updateCompany: builder.mutation({
      query: (company) => ({
        url: `${company.id}/`,
        method: "PUT",
        body: company,
      }),
      invalidatesTags: ["Company"],
    }),
    removeCompany: builder.mutation({
      query: (id) => ({
        url: `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useRemoveCompanyMutation,
} = companiesApi;
