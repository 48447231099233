import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

//import orderReducer from "entities/orders/model/orderSlice";
import viewerReducer from "entities/viewer/model/viewerSlice";
//import fileReducer from "entities/files/model";
//import offersReducer from "entities/offers/model";
//import commentsReducer from "entities/comments/model";
//import actionsReducer from "entities/actions/model/actionsSlice";
//import filtersReducer from "entities/filters/model/filterSlice";
//import tasksReducer from "entities/progressboard/model/tasksSlice";
//import publicsReducer from "entities/publics/model/publicsSlice";
//import waReducer from "entities/whatsapp/model/waSlice";
//import leadsReducer from "entities/crm/leads/model/leadsSlice";
//import contactsReducer from "entities/crm/contacts/model/contactsSlice";
//import companiesReducer from "entities/crm/companies/model/companiesSlice";
//import servicesReducer from "entities/services/model/servicesSlice";

//import waMiddleware from "./middleware/wapp";
//import invoiceReducer from "entities/invoices/model/invoiceSlice";
import messagesReducer from "entities/messages/model/messagesSlice";
//import quoteReducer from "entities/quotes/model/quoteSlice";
//import vehiclesReducer from "entities/vehicles/api/vehiclesSlice";

import windowsReducer from "app/windows/windowsSlice";
import { crmApi } from "entities/crm/leads/model/qapi";
import { invoiceApi } from "entities/invoices/model/api";
import { extdataApi } from "entities/extdata/model/api";
import { contactsApi } from "entities/crm/contacts/model/rtkapi";
import { companiesApi } from "entities/crm/companies/model/rtkapi";
import { vehiclesApi } from "entities/vehicles/api/api";
import { ordersApi } from "entities/orders/model/rtk-api";
import { usedApi } from "entities/used/api/api";
import { managersApi } from 'entities/managers/model/rtk-api';
import { publicsApi } from "entities/publics/model/api";
import { manOrderApi } from "entities/man/model/api";
import { quoteApi } from "entities/quotes/model/rtk-api";
import { servicesApi } from "entities/services/model/rtk-api";
import { filesApi } from "entities/files/model/rtk-api";
import { commentsApi } from "entities/comments/model/rtkapi";
import { taskApi } from "entities/tasks/model/api";
import { actionsApi } from "entities/actions/model/actionsApi";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 1,
  });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    //orders: orderReducer,
    //filters: filtersReducer,
    viewer: viewerReducer,
    //files: fileReducer,
    //offers: offersReducer,
    //comments: commentsReducer,
    // actions: actionsReducer,
    //tasks: tasksReducer,
    //publics: publicsReducer,
    //leads: leadsReducer,
    //wasocket: waReducer,
    //contacts: contactsReducer,
    //companies: companiesReducer,
    //invoices: invoiceReducer,
    messages: messagesReducer,
    //quotes: quoteReducer,
    //services: servicesReducer,
    //vehicles: vehiclesReducer,
    windows: windowsReducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [crmApi.reducerPath]: crmApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [extdataApi.reducerPath]: extdataApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [vehiclesApi.reducerPath]: vehiclesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [usedApi.reducerPath]: usedApi.reducer,
    [managersApi.reducerPath]: managersApi.reducer,
    [publicsApi.reducerPath]: publicsApi.reducer,
    [manOrderApi.reducerPath]: manOrderApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // waMiddleware,
      routerMiddleware,
      actionsApi.middleware,
      crmApi.middleware,
      invoiceApi.middleware,
      extdataApi.middleware,
      contactsApi.middleware,
      companiesApi.middleware,
      vehiclesApi.middleware,
      ordersApi.middleware,
      usedApi.middleware,
      managersApi.middleware,
      publicsApi.middleware,
      manOrderApi.middleware,
      quoteApi.middleware,
      servicesApi.middleware,
      filesApi.middleware,
      commentsApi.middleware,
      taskApi.middleware,
      //waMiddleware,
    ]),
});

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
