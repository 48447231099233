import { Modal } from "shared/ui/Modal";
import { useGetCompanyByIdQuery, useRemoveCompanyMutation } from "../model/rtkapi";
import { LeadCard } from "entities/crm/leads/ui/LeadCard";
import { ContactCard } from "entities/crm/contacts/ui/ContactCard";
import { OrderCard } from "entities/orders/ui/OrderCard";
import { Button } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { openWindow } from "app/windows/windowsSlice";
import { useSearchParams } from "react-router-dom";

interface CompanyDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
}

export const CompanyDetails = ({ isOpen, onClose, companyId }: CompanyDetailsProps) => {
  const dispatch = useAppDispatch();
  const { data: company } = useGetCompanyByIdQuery(companyId);
  const [removeCompany] = useRemoveCompanyMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const openCompanyFormHandler = (id: string) => {
    dispatch(openWindow("companyFormWindow"));
    if (companyId) {
      searchParams.set("company", companyId);
      setSearchParams(searchParams);
    }
  };

  const handleRemoveCompany = () => {
    removeCompany(companyId);
    onClose();
  }
  return (
    <Modal className="z-50" isOpen={isOpen} onClose={onClose}>
      {company && (
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Dettagli Azienda</h2>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col border border-gray-300 rounded-md p-2 bg-white text-sm">
              <div>ID: {company.id}</div>
              <div>Nome: {company.name} </div>
              <div>Comune: {company.comune} </div>
              <div>Indirizzo: {company.address} </div>
              <div>Sito: {company.site} </div>
              <div>Descrizione: {company.desc} </div>
              <div
                onClick={() => openCompanyFormHandler(company.id)}
                className="cursor-pointer text-blue-500">
                Modifica
              </div>
            </div>
            <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
              <span className="absolute top-1 left-3 text-[10px]">CONTATTI</span>
              {company.contacts?.map((contact) => <ContactCard contactId={contact} />)}
            </div>
            <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
              <span className="absolute top-1 left-3 text-[10px]">LEAD</span>
              {company.leads?.map((lead) => <div className="w-auto"><LeadCard lead={lead} /></div>)}
            </div>

            <div className="relative flex flex-row gap-2 flex-wrap p-2 pt-6 bg-gray-100 rounded-md">
              <span className="absolute top-1 left-3 text-[10px]">VEICOLI</span>
              {company.vehicles?.map((vehicle) => <OrderCard order={vehicle} />)}
            </div>
            {/* <div>
              <p className="font-semibold">Contatti:</p>
              <p>{company.contacts}</p>
            </div>
            <div>
              <p className="font-semibold">Veicoli:</p>
              <p>{company.vehicles}</p>
            </div> */}
            {company.leads?.length === 0 && company.contacts?.length === 0 && company.vehicles?.length === 0 &&
              <Button variant="contained" onClick={handleRemoveCompany} >Remove</Button>
            }
          </div>
        </div>
      )}
    </Modal>
  );
};
