import { useAppDispatch } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useGetCompanyByIdQuery } from "../model/rtkapi";
import EditIcon from "@mui/icons-material/Edit";
import { openWindow } from "app/windows/windowsSlice";

interface Props {
  companyId: string;
}

export const CompanyCard = ({ companyId }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: company } = useGetCompanyByIdQuery(companyId, {
    skip: !companyId,
  });
  const openCompanyFormHandler = (id: string) => {
    dispatch(openWindow("companyFormWindow"));
    if (companyId) {
      searchParams.set("company", companyId);
      setSearchParams(searchParams);
    }
  };
  return (
    <>
      {company ? (
        <>
          <div
            style={{
              padding: "5px",
              border: "1px dotted grey",
              position: "relative",
            }}
          >
            <div>
              <div>Azienda: {company.name}</div>
              {company.comune && <div>Comune: {company.comune}</div>}
              {company.site && <div>Sito: {company.site}</div>}
              {company.desc && <div>Info: {company.desc}</div>}
            </div>
            <EditIcon
              sx={{
                fontSize: "16px",
                position: "absolute",
                bottom: "3px",
                right: "5px",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => openCompanyFormHandler(company.id)}
            />
          </div>
        </>
      ) : (
        <Stack>Azienda non associata</Stack>
      )}
    </>
  );
};
