import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Vehicle } from "./types";

interface GetVehiclesParams {
  user?: string;
  year_of_sale?: string;
}

export const vehiclesApi = createApi({
  reducerPath: "vehiclesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/vehicle/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Vehicles", "Vehicle"],
  endpoints: (builder) => ({
    getVehicles: builder.query<Vehicle[], any>({
      query: (params) => {
        const searchParams = new URLSearchParams();
        if (params.user) searchParams.append('user', params.user);
        if (params.year_of_sale) searchParams.append('year_of_sale', params.year_of_sale);
        if (params.company) searchParams.append('company', params.company);
        if (params.seller) searchParams.append('seller', params.seller);
        if (params.model) searchParams.append('model', params.model);
        return `?${searchParams.toString()}`;
      },
      providesTags: ["Vehicles"],
    }),
    getVehicleById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Vehicle"],
    }),
    // addVehicle: builder.mutation({
    //   query: (contact) => ({
    //     url: "",
    //     method: "POST",
    //     body: contact,
    //   }),
    // }),
    updateVehicle: builder.mutation({
      query: (vehicle) => ({
        url: `${vehicle.id}/`,
        method: "PUT",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicles", "Vehicle"],
    }),
    // removeUser: builder.mutation({
    //   query: (id) => ({
    //     url: id,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
} = vehiclesApi;
