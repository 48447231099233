// components/Renderers/OrderRenderer.tsx
import React from 'react';
import { useGetOrdersByCodeQuery, useUpdateOrderMutation } from 'entities/orders/model/rtk-api';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { openWindow } from 'app/windows/windowsSlice';
import { useUploadFileMutation } from 'entities/files/model';

const OrderRenderer: React.FC<{ data: any, file: File | null }> = ({ data, file }) => {

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: orders, isLoading } = useGetOrdersByCodeQuery(data.auftrag);
  const [updateOrders, { isLoading: isUpdating }] = useUpdateOrderMutation();
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();

  const handleOpenOrder = (id: number) => {
    searchParams.set("order", id.toString());
    setSearchParams(searchParams);
    dispatch(openWindow("newOrderWindow"));
  };

  const handleUpdateOrders = () => {
    orders?.forEach((order) => {
      updateOrders({
        ...order,
        list_price: data.veicoloFattura,
        discount: "0",
        margin: "0",
        opt_ext_margin: "0",
        trans_price: "0"
      });
      console.log(file)
      uploadFile({
        files: [file],
        orderId: order.id,
        fileType: "ORDINE"
      });
    });
  };
  return (
    <div className="p-4 border rounded bg-gray-50">
      <h2 className="text-lg font-bold">Order #{data.rif}</h2>
      <div className="flex gap-2">
        AUFTRAG:
        {data.auftrag.map((item: any, index: number) => (
          <div key={index}>
            {item},
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        {isLoading ? <p>Loading...</p> : (
          <>
            ORDERS:
            {orders?.map((item: any, index: number) => (
              <div className="cursor-pointer text-blue-500 hover:underline" key={index} onClick={() => { handleOpenOrder(item.id) }}>
                #{item.id} ({item.code}),
              </div>
            ))}
          </>
        )}
      </div>
      <p>Lordo Listino: {data.lordoListino}</p>
      <p>Trasporto: {data.trasporto}</p>
      <p>Allestimento: {data.allestimento}</p>
      <p>Sconto effettivo: {(((data.lordoListino - (data.veicoloFattura - data.trasporto)) / data.lordoListino) * 100).toFixed(2)}%</p>
      <p className="font-bold">Totale Veicolo Fattura: {data.veicoloFattura}</p>
      {isUploading || isUpdating ? <p>Uploading...</p> : (
        <Button variant="contained" color="primary" onClick={handleUpdateOrders}>
          Applica veicolo fattura a tutti gli auftrag
        </Button>
      )}
    </div>
  );
};
export default OrderRenderer;

