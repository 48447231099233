import { useGetVehiclesQuery } from "entities/vehicles/api/api";
import { vehicleStatusesList } from "entities/vehicles/api/constants";
import { Vehicle } from "entities/vehicles/api/types";
import { VehicleList } from "entities/vehicles/ui/VehicleList";
import { Reloader } from "shared/ui/Reloader/Reloader";
import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { getViewer } from "entities/viewer/model";
// Importazioni MUI
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  Grid,
  CircularProgress
} from '@mui/material';
import { useGetManagersQuery } from "entities/managers/model/rtk-api";
import { Manager } from "entities/managers/model/types";
import { modelList } from "entities/filters/model/filterSlice";

interface Filters {
  year_of_sale: string;
  seller: string;
  company: string;
  model:string;
}

interface Props {
  leadsOwner?: string;
 // searchString: string;
}

const initialFilters: Filters = {
  year_of_sale: new Date().getFullYear().toString(),
  seller: "",
  company: "",
  model: "",
}

const models = ["TGX", "TGS", "TGE", "TGL", "TGM"]

export const DeliveryBoard = ({leadsOwner}:Props) => {
  const user = useAppSelector(getViewer)
  const currentYear = new Date().getFullYear();
  const [filters, setFilters] = useState<Filters>(initialFilters);

  // Genera le opzioni per gli ultimi 4 anni
  const yearOptions = Array.from({ length: 4 }, (_, i) => ({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString()
  }));
  yearOptions.unshift({ value: "", label: "Tutti gli anni" });

  useEffect(() => {
    setFilters({ ...filters, seller: leadsOwner ? leadsOwner : (user?.id || "") });
  }, [user]);

  const {
    data: vehicles,
    isLoading,
    isSuccess,
  } = useGetVehiclesQuery(
    filters,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: managers } = useGetManagersQuery();
  const sellerList = managers ? Object.values(managers).filter((item) => item.seller) : [];


  return (
    <div>
      <div className="flex flex-row gap-2 my-2">

        {user?.roles.includes("crm_manager") && sellerList && (
          <Select
            size="small"
            sx={{ width: "200px" }}
            value={filters.seller}
            onChange={(e) => setFilters({
              ...filters,
              seller: e.target.value
            })}
          >
            {sellerList.map((item: Manager) => (
              <MenuItem key={item.id} value={item.user}>
                {item.name}
              </MenuItem>
            ))}
            <MenuItem value="">Tutti i venditori</MenuItem>
          </Select>

        )}

        <TextField
          size="small"
          placeholder="Cerca per nome"
          variant="outlined"
          onChange={(e) => setFilters({
            ...filters,
            company: e.target.value
          })}
        />


        <FormControl size="small">
          <Select
            value={filters.year_of_sale}
            onChange={(e) => setFilters({
              ...filters,
              year_of_sale: e.target.value
            })}
            displayEmpty
          >
            <MenuItem value="">Tutti gli anni</MenuItem>
            {yearOptions.slice(1).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <Select
            value={filters.model}
            size="small"
            onChange={(e) => setFilters({
              ...filters,
              model: e.target.value
            })}
          >
            <MenuItem value="">Tutti i modelli</MenuItem>
            {models?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          size="small"
          onClick={() => setFilters(initialFilters)}
        >
          Reset
        </Button>
      </div>

      {
        isSuccess && (
          <div className="flex flex-row gap-2">
            {vehicleStatusesList.map((item) => (
              <VehicleList
                key={item.seq}
                vehicles={vehicles}
                title={item.title}
                status={item.value}
              />
            ))}
          </div>
        )
      }
      {
        isLoading && (
          <CircularProgress size={24} />

        )
      }

    </div>
  );
};
